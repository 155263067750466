import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Assets } from "utils/Assets";

import NonAuthLayout from "Layout/NonAuthLayout";
import AuthLayout from "Layout/AuthLayout";

import LanguageSelector from "pages/auth/LanguageSelector";
import Register from "pages/auth/Register";
import Login from "pages/auth/Login";
import Forgot from "pages/auth/Forgot";
import Welcome from "pages/welcome/Welcome";
import SelectTeam from "pages/addteam/SelectTeam";
import SelectPayment from "pages/selectpayment/SelectPayment";
import MyPlan from "pages/myplan/MyPlan";
import Drills from "pages/myplan/Drills";
import DrillsDetail from "pages/myplan/DrillsDetail";
import Session from "pages/myplan/Session";
import Universals from "pages/universals/Universals";
import UniversalDetail from "pages/universals/UniversalDetail";
import Setting from "pages/setting/Setting";
import VerifyScreen from "pages/auth/VerifyScreen";
import IntermediateLayout from "Layout/IntermediateLayout";

const Router = () => {
    return (
        <>
            <Toaster />
            <BrowserRouter>
                <Suspense fallback={<div className="d-flex justify-content-center align-items-center border vh-100"><img src={Assets.darkLogo} alt="" width='250px' /></div>}>
                    <Routes>
                        <Route element={<AuthLayout />}>
                            <Route path='/my-plan' element={<MyPlan />} />
                            <Route path='/session/:id' element={<Session />} />
                            <Route path='/drills/:id' element={<Drills />} />
                            <Route path='/drill-details/:id' element={<DrillsDetail />} />
                            <Route path='/universals' element={<Universals />} />
                            <Route path='/universal-detail/:id' element={<UniversalDetail />} />
                            <Route path='/settings' element={<Setting />} />
                        </Route>
                        <Route element={<NonAuthLayout />}>
                            <Route path='/' element={<LanguageSelector />} />
                            <Route path='/register' element={<Register />} />
                            <Route path='/login' element={<Login />} />
                            <Route path='/forgot' element={<Forgot />} />
                            <Route path='/verify' element={<VerifyScreen />} />
                        </Route>
                        <Route element={<IntermediateLayout />}>
                            <Route path='/teams' element={<SelectTeam />} />
                            <Route path='/welcome' element={<Welcome />} />
                            <Route path='/payment' element={<SelectPayment />} />
                        </Route>
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </>
    )
}

export default Router;
