import { useEffect, useState } from "react";
import { Form, Modal, Spin, Upload } from "antd";
import { Assets } from "utils/Assets";
import { useTranslation } from "react-i18next";
import { useUploadFileMutation } from "../redux/features/common/commonApi";
import { useCreateTeamMutation, usePlansListQuery, useUpdateTeamMutation } from "../redux/features/myplan/planApi";
import CustomInput from "./CustomInput";
import CustomButton from "./CustomButton";
import Toast from "utils/Toast";
import { file_url } from "constant/url";

const CreateTeamModal = ({ teamModal, setTeamModal, teamDetail, setTeamDetail }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [fileList, setFileList] = useState([]);
    const [selectPlan, setSelectPlan] = useState('');
    const [imageFile, setImageFile] = useState('');
    const { data } = usePlansListQuery();
    const [uploadfile, { isLoading: uploadLoader }] = useUploadFileMutation();
    const [createTeam, { isLoading }] = useCreateTeamMutation();
    const [updateTeam, { isLoading: updateLoader }] = useUpdateTeamMutation();

    const handleUploadImage = async (file) => {
        try {
            const formData = new FormData();
            if (file) {
                formData.append('team_media', file)
            }
            const result = await uploadfile(formData).unwrap();
            setImageFile(result?.data?.[0])
        } catch (error) {
            Toast("e", error?.data?.message ?? error?.status);
        }
    };

    const createNewTeam = async (formVal) => {
        try {
            const payload = {
                team_image: imageFile,
                team_name: formVal?.team_name,
                plan_id: selectPlan,
            };
            const res = await createTeam(payload).unwrap();
            if (res) {
                setTeamModal(false);
                Toast("s", res?.message);
                form.resetFields();
                setSelectPlan('');
                setFileList([]);
                setImageFile('');
            }
        } catch (error) {
            Toast("e", error?.data?.message ?? error?.status);
        }
    };

    const handleUpdateTeam = async (formVal) => {
        try {
            // if (fileList.length < 1) {
            //     return Toast('e', 'Please upload team logo')
            // }
            const payload = {
                team_id: teamDetail?._id,
                team_image: imageFile ? imageFile : "",
                team_name: formVal?.team_name,
                plan_id: selectPlan,
            };
            const res = await updateTeam(payload).unwrap();
            if (res) {
                setTeamModal(false);
                Toast("s", res?.message);
                form.resetFields();
                setSelectPlan('');
                setFileList([]);
            }
        } catch (error) {
            Toast("e", error?.data?.message ?? error?.status);
        }
    };

    const handleCancelModal = () => {
        form.resetFields();
        setTeamModal(false);
        setSelectPlan('');
        setFileList([]);
        if (teamDetail) {
            setTeamDetail(null);
        }
    };

    useEffect(() => {
        if (teamDetail) {
            form.setFieldsValue({
                team_name: teamDetail?.team_name
            })
            setSelectPlan(teamDetail?.plan_id?._id);
            if (teamDetail?.team_image) {
                setFileList([{ url: `${file_url}${teamDetail?.team_image}` }])
            } else {
                setFileList([])
            }
        }
    }, [teamDetail]);

    return (
        <>
            <Modal open={teamModal} onCancel={handleCancelModal} footer='' closeIcon={<img src={Assets.closeIcon} alt="" />}>
                <h4 className="medium_font fw-bold mt-2 mb-md-4 mb-lg-4">{!teamDetail ? t("my_plan.new_team") : t("my_plan.edit_team")}</h4>
                <Form form={form} onFinish={!teamDetail ? createNewTeam : handleUpdateTeam} layout="vertical">
                    <Form.Item label={<dt style={{ color: `var(--text-gray)` }} className="small_font">{t("my_plan.team_logo")}:</dt>}>
                        <Spin spinning={uploadLoader} style={{ width: '100px' }}>
                            <Upload
                                listType="picture-card"
                                fileList={fileList}
                                onChange={({ fileList: newFileList }) => setFileList(newFileList)}
                                accept={"image/*"}
                                beforeUpload={handleUploadImage}
                                showUploadList={{
                                    showPreviewIcon: false,
                                }}
                            >
                                {fileList.length >= 1 ? null : <img src={Assets.upload} alt="" width='100%' />}
                            </Upload>
                        </Spin>
                    </Form.Item>
                    <Form.Item name='team_name' label={<dt style={{ color: `var(--text-gray)` }} className="small_font">{t("my_plan.team_name")}:</dt>}>
                        <CustomInput required className="border-black text-dark" placeholder={t("placeholder.team_name_here")} />
                    </Form.Item>
                    <Form.Item name='plan_id' label={<dt style={{ color: `var(--text-gray)` }} className="small_font">{t("my_plan.choose_plan")}:</dt>}>
                        <div className="row g-3">
                            {data?.data?.map((item) => {
                                return (
                                    <div className="col-6 col-md-4 col-lg-4" key={item?._id}>
                                        <div className="d-flex align-items-center gap-2">
                                            <div tabIndex={0} onKeyDown={() => '_'} role="button" className="radio rounded-circle p-1" onClick={() => setSelectPlan(item?._id)}>
                                                <div className={selectPlan === item?._id ? 'mini_radio rounded circle' : 'mini_radio bg-white'} />
                                            </div>
                                            <div>
                                                <dt className="fw-bold fs-6">Plan: {item?.plan_name}</dt>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className="d-flex gap-3 mt-2">
                            <div className="btn_border border-black w-50">
                                <CustomButton
                                    style={{ background: `var(--green)`, color: `var(--black)`, width: '100%' }}
                                    htmlType='submit'
                                    loading={!teamDetail ? isLoading : updateLoader}
                                >
                                    {t("buttons.save")}
                                </CustomButton>
                            </div>
                            <div className="btn_border border-black w-50">
                                <CustomButton
                                    style={{ background: `var(--btn-gray)`, color: `var(--black)`, width: '100%' }}
                                    onClick={handleCancelModal}
                                >
                                    {t("buttons.cancel")}
                                </CustomButton>
                            </div>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default CreateTeamModal;
