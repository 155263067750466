const { Assets } = require("utils/Assets");

const SplashScreen = () => {
    return (
        <>
            <div className="auth_div">
                <div className='row g-0'>
                    <div className='col-md-6 col-lg-6 d-md-block d-lg-block d-none'>
                        <div className="d-flex align-items-center justify-content-between">
                            <img src={Assets.darkLightBall} alt='' width='85%' />
                            <div className="border_" />
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-6'>
                        <div className="row g-0">
                            <div className="col-11 col-md-10 col-lg-8 mx-auto" style={{ position: 'relative', height: '100vh', display: 'flex', justifyContent: 'center', zIndex: 1 }}>
                                <div className="d-flex align-items-center text-start gap-3">
                                    <img src={Assets.logoBall} alt="" width='50px' />
                                    <dt className="medium_font text-white">Premier Futbol <br /> Institue</dt>
                                </div>
                            </div>
                            <img src={Assets.unionOne} alt="" width='100%' className="d-block d-md-none d-lg-none footer_auth_img" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SplashScreen;
