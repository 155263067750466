import { Form } from "antd"
import { Assets } from "utils/Assets"
import { Icons } from "utils/Icons";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLoginMutation } from "../../redux/features/auth/authApi";
import { useDispatch, useSelector } from "react-redux";
import { promoCodeData, userToken } from "../../redux/features/common/commonSlice";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { socialAuth } from "firebase.config";
import { deviceType } from "utils/Constant";
import CustomInput from "components/CustomInput"
import CustomButton from "components/CustomButton";
import Toast from "utils/Toast";
import PasswordInput from "components/PasswordInput";


const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [login, { isLoading }] = useLoginMutation();
    const langCode = useSelector(state => state?.common?.lang_code);

    const handleLogin = async (formVal) => {
        try {
            const payload = {
                email: formVal?.email?.toLowerCase(),
                password: formVal.password,
                account_source: 'email',
                language: langCode ? langCode : 'en',
                device: deviceType
            };
            const res = await login(payload).unwrap();
            if (res) {
                dispatch(userToken(res?.data))
                localStorage.setItem('token', JSON.stringify(res?.data))
                if (res?.data?.is_subscription_purchased) {
                    navigate('/my-plan');
                } else {
                    navigate('/teams')
                    dispatch(promoCodeData({}));
                }
            }
        } catch (error) {
            console.log(error)
            if (error?.data?.data?.is_verified_user === false) {
                navigate('/verify')
            } else {
                Toast("e", error?.data?.message ?? error?.status);
            }
        }
    };

    // Social Logins
    const handleGoogleLogin = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(socialAuth, provider);
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // console.log(credential, 'credentialcredentials')
            // The signed-in user info.
            const user = result.user;
            const payload = {
                account_source: 'google',
                social_token: token,
                full_name: user?.displayName || user?.providerData?.[0]?.displayName,
                email: user?.email || user?.providerData?.[0]?.email,
                language: langCode ? langCode : 'en',
                device: deviceType
            };

            const res = await login(payload).unwrap();
            if (res) {
                localStorage.setItem('token', JSON.stringify(res?.data));
                if (res?.data?.is_subscription_purchased) {
                    return navigate('/my-plan');
                } else {
                    navigate('/teams')
                    dispatch(promoCodeData({}));
                }
            }
        } catch (error) {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            Toast("e", errorMessage ?? errorCode);
        }
    };

    // const handleAppleLogin = () => {

    // };
    // const handleFacebookLogin = () => {

    // };

    return (
        <>
            <div className="extra_margin mt-md-0 mt-lg-0">
                <div className="d-flex align-items-start justify-content-between">
                    <h1 className="big_font text-white mb-3 mb-md-4 mb-lg-4">{t("auth.login")}</h1>
                    <img src={Assets.logoBall} alt="" width='60px' className="d-md-none d-lg-none d-block" />
                </div>
                <Form onFinish={handleLogin} layout="vertical">
                    <Form.Item name='email' label={<dt className="small_font">{t("auth.email")}:</dt>}>
                        <CustomInput type="email" required={true} />
                    </Form.Item>
                    <Form.Item name='password' label={<dt className="small_font">{t("auth.password")}:</dt>}>
                        <PasswordInput />
                    </Form.Item>
                    <Form.Item>
                        <div className="d-flex justify-content-between">
                            <dt><Link to='/forgot' style={{ color: `var(--green)` }}>{t("auth.forgot_password")}?</Link></dt>
                            <dt><Link to='/' style={{ color: `var(--green)` }}>{t("settings.change_language")}</Link></dt>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className="btn_border">
                            <CustomButton
                                htmlType='submit'
                                className={` w-100`}
                                style={{ background: `var(--green)`, color: `var(--black)` }}
                                loading={isLoading}
                            >
                                {t("auth.login")}
                            </CustomButton>
                        </div>
                    </Form.Item>
                </Form>
            </div>
            <div className="">
                <dt className="text-end text-white mb-4">{t("auth.login_with")}:</dt>
                <div className="row g-3">
                    {/* <div className="col-4">
                        <div className="btn_border">
                            <CustomButton
                                onClick={handleAppleLogin}
                                className={` w-100 px-0`}
                                style={{ background: `var(--white)` }}
                            >
                                <Icons.FaApple color={`var(--dark-gray)`} size={30} />
                            </CustomButton>
                        </div>
                    </div> */}
                    <div className="d-flex justify-content-end">
                        <div className="btn_border">
                            <CustomButton
                                onClick={handleGoogleLogin}
                                style={{ background: `var(--white)` }}
                            >
                                <Icons.FcGoogle size={30} />
                            </CustomButton>
                        </div>
                    </div>
                    {/* <div className="col-4">
                        <div className="btn_border">
                            <CustomButton
                                onClick={handleFacebookLogin}
                                className={` w-100 px-0`}
                                style={{ background: `var(--white)` }}
                            >
                                <Icons.FaFacebookF color={`var(--dark-blue)`} size={25} />
                            </CustomButton>
                        </div>
                    </div> */}
                </div>
            </div>
            <dt className="text-end text-white my-4">{t("auth.dont_have_account")}</dt>
            <div className="d-flex justify-content-end">
                <div className="btn_border">
                    <CustomButton
                        onClick={() => navigate('/register')}
                        className={``}
                        style={{ background: `var(--blue)` }}
                    >
                        {t("auth.register")}
                    </CustomButton>
                </div>
            </div>
        </>
    )
}

export default Login;
