import { baseApi } from "../../app/baseApi";
import { endpoints } from "utils/Endpoint";

const planApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        plansList: builder.query({
            query: () => ({
                url: endpoints?.plan_list,
                method: 'GET',
            }),
        }),
        listTeam: builder.query({
            providesTags: ['listTeam'],
            query: () => ({
                url: endpoints?.list_team,
                method: 'GET',
            }),
        }),
        createTeam: builder.mutation({
            query: (data) => ({
                url: endpoints?.create_team,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['listTeam', 'getUser']
        }),
        updateTeam: builder.mutation({
            query: (data) => ({
                url: endpoints?.edit_team,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['listTeam', 'listSession']
        }),
        listSession: builder.query({
            providesTags: ['listSession'],
            query: (team_id) => ({
                url: `${endpoints?.list_session}${team_id}`,
                method: 'GET'
            }),
        }),
        updateSession: builder.mutation({
            query: (data) => ({
                url: `${endpoints?.update_session}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ['listSession']
        }),
        sessionProgress: builder.mutation({
            query: (data) => ({
                url: `${endpoints?.session_progress}`,
                method: 'POST',
                body: data
            }),
            // invalidatesTags: ['listSession']
        }),
        resetSession: builder.mutation({
            query: () => ({
                url: `${endpoints?.reset_session}`,
                method: 'PUT',
            }),
            invalidatesTags: ['listSession']
        }),
        cycleSteps: builder.query({
            query: (team_id) => ({
                url: `${endpoints?.cycle_steps}${team_id}`,
                method: 'GET',
            }),
        }),
        listDrills: builder.query({
            query: (session_id) => ({
                url: `${endpoints?.list_drills}${session_id}`,
                method: 'GET',
            }),
        }),
        completeDrill: builder.mutation({
            query: (data) => ({
                url: `${endpoints?.complete_drills}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ['listSession']
        }),
    }),
});

export const {
    useListTeamQuery,
    usePlansListQuery,
    useCreateTeamMutation,
    useUpdateTeamMutation,
    useListSessionQuery,
    useUpdateSessionMutation,
    useSessionProgressMutation,
    useResetSessionMutation,
    useCycleStepsQuery,
    useListDrillsQuery,
    useCompleteDrillMutation,
} = planApi;