import { toast } from 'react-hot-toast';

const Toast = (type, message, position = 'top-right', duration = 1000) => {
    const defaultOptions = {
        position,
        duration,
        style: {
            background: '#333',
            color: '#fff',
        },
        icon: null,
    };
    const options = {
        ...defaultOptions,
        ...(type === 'e' ? {
            style: {
                background: '#000',
                border: '2px solid #f44336',
                color: '#fff',
                padding: '0.8rem 1.2rem',
                font: '700 1rem Figtree, sans-serif'
            },
            // icon: '❌',
        } : {}),
        ...(type === 's' ? {
            style: {
                background: '#000',
                border: '2px solid #00e85c',
                color: '#fff',
                padding: '0.8rem 1.2rem',
                font: '700 1rem Figtree, sans-serif'
            },
            // icon: '✅',
        } : {}),
    };

    toast(message, options);
}

export default Toast;