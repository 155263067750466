import { useState } from "react";
import { loadStripe } from '@stripe/stripe-js';
import { Modal } from "antd";
import { Assets } from "utils/Assets";
import { useTranslation } from "react-i18next";
import { useChargePaymentMutation, useLisCardQuery } from "../../redux/features/payment/paymentApi";
import { commonData, promoCodeData } from "../../redux/features/common/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { stripeKey } from "constant/url";
import { useLocation } from "react-router-dom";
import { useUserDetailQuery } from "../../redux/features/settings/settingApi";
import CustomButton from "components/CustomButton";
import PaymentModal from "./cardpayment/PaymentModal";
import Toast from "utils/Toast";
import PaymentSuccessModal from "components/PaymentSuccessModal";
import PaymentCard from "components/PaymentCard";
import useWindowHeight from "utils/useWindowHeight";

// Declare google as a global variable for ESLint
/* global google */

const SelectPayment = () => {
    const [cardModal, setCardModal] = useState(false);
    const [upgradeModal, setUpgradeModal] = useState(false);
    const [activeCard, setActiveCard] = useState(null);
    const [paymentSuccessModal, setPaymentSuccessModal] = useState(false);
    const [planType, setPlanType] = useState('');
    const dispatch = useDispatch();
    const location = useLocation();
    const windowHeight = useWindowHeight();
    const stripePromise = loadStripe(stripeKey);
    const { t, i18n } = useTranslation();
    const { data, error } = useLisCardQuery();
    const { refetch } = useUserDetailQuery();
    const [chargePayment, { isLoading }] = useChargePaymentMutation();
    const reduxData = useSelector(state => state?.common);
    const quantityAndAmount = reduxData?.common_data;
    const paymentCardData = reduxData?.payment_card;
    // const userData = reduxData?.user_data;
    // const teamQuantity = Number(quantityAndAmount?.quantity);
    console.log(quantityAndAmount, 'quantityAndAmountquantityAndAmountquantityAndAmount')

    // Handling card data and errors
    const listCard = error?.data?.status === false ? {} : data?.data;

    const handlePayAmount = async () => {
        const cardData = listCard ? Object.keys(listCard)?.length > 0 : false;
        if (!cardData) {
            return Toast("e", 'Please add card detail!');
        }

        try {
            const payload = {
                card_id: listCard?.id,
                no_of_teams: quantityAndAmount?.subscriptionData?.name,
                total_amount: quantityAndAmount?.discountedAmount || quantityAndAmount?.discountedAmount === 0
                    ? quantityAndAmount?.discountedAmount
                    : quantityAndAmount?.subscriptionData?.price,
                price_id: quantityAndAmount?.subscriptionData?.default_price,
            };

            // return
            const res = await chargePayment(payload).unwrap();
            // console.log(res,'resresressuccesssssssss')
            if (res) {
                if (res?.data?.type === "updated") {
                    setPlanType(res?.data);
                    setPaymentSuccessModal(true);
                    refetch();
                } else {
                    handlePaymentSuccess(res?.data?.client_secret);
                }
            }
        } catch (error) {
            Toast("e", error?.data?.message ?? error?.status);
        }
    };

    const handlePaymentSuccess = async (clientSecret) => {
        const stripe = await stripePromise;
        const { error } = await stripe.confirmCardPayment(clientSecret);

        if (error) {
            Toast("e", error.message);
        } else {
            setPaymentSuccessModal(true);
            dispatch(commonData({}));
            dispatch(promoCodeData());
            refetch();
        }
    };

    const handleGooglePay = async () => {
        const paymentRequest = {
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
                {
                    type: 'CARD',
                    parameters: {
                        allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                        allowedCardNetworks: [
                            'AMEX',      // American Express
                            'DISCOVER',  // Discover
                            'JCB',       // JCB
                            'MASTERCARD',// Mastercard
                            'VISA',      // Visa
                            'INTERAC'    // Interac (if applicable)
                            // You can add more networks if needed
                        ],
                    },
                    tokenizationSpecification: {
                        type: 'PAYMENT_GATEWAY',
                        parameters: {
                            gateway: 'stripe',
                            gatewayMerchantId: 'exampleGatewayMerchantId',
                        },
                    },
                },
            ],
            merchantInfo: {
                merchantId: '12345678901234567890',
                merchantName: 'Demo Merchant',
            },
            transactionInfo: {
                totalPriceStatus: 'FINAL',
                totalPriceLabel: 'Total',
                totalPrice: '1.00',
                currencyCode: 'USD',
                countryCode: 'US',
            },
        };

        const googlePayClient = new google.payments.api.PaymentsClient({ environment: 'TEST' });

        try {
            const paymentData = await googlePayClient.loadPaymentData(paymentRequest);
            console.log('Payment data loaded:', paymentData);
            // Handle the payment data here
        } catch (error) {
            console.error('Error loading payment data:', error);
            // Log more details about the error
            if (error.statusCode) {
                console.error('Status Code:', error.statusCode);
            }
            if (error.message) {
                console.error('Error Message:', error.message);
            }
        }
    };

    const noFunc = () => {
        if (!activeCard) {
            return Toast("e", 'Please select payment method!');
        }
    };


    return (
        <>
            <div className={location?.pathname === '/settings' ? "" : "extra_margin mt-md-0 mt-lg-0 mt-xl-5"}>
                {location?.pathname === '/settings' ? "" :
                    <div className="d-flex align-items-start justify-content-between">
                        <h1 className="big_font text-white mb-4 mb-xl-5">{t("payment_method.select_payment")}:</h1>
                        <img src={Assets.logoBall} alt="" width='60px' className="d-md-none d-lg-none d-block" />
                    </div>
                }
                {/* {paymentCardOptions.map((item) => (
                    <div
                        className="common_card mb-4"
                        key={item.id}
                        role="button"
                        tabIndex={0}
                        onKeyDown={handleEnterKeyDown(item.id, setActiveCard)}
                        onClick={() => setActiveCard(item.id)}
                        style={{ height: '60px' }}
                    >
                        <div className="d-flex align-items-center gap-2">
                            <div className="radio">
                                <div className={activeCard === item.id ? `mini_radio` : 'mini_radio bg-white'} />
                            </div>
                            <div>
                                <dt className="fw-bold fs-6">
                                    {item.id === 1
                                        ? listCard?.brand
                                            ? `${listCard.brand} ${listCard.funding ? `(${listCard.funding})` : ''}`
                                            : "Credit Card"
                                        : item.card_name}
                                </dt>
                                {item.id === 1 && listCard?.last4 &&
                                    <dt className="so_small_font text-secondary">XXXX-XXXX-{listCard.last4}</dt>}
                            </div>
                        </div>
                        <img src={item.icon} alt="" />
                    </div>
                ))} */}
                <PaymentCard activeCard={activeCard} setActiveCard={setActiveCard} listCard={listCard} />

            </div>
            {location?.pathname === '/settings' ?
                <div className={windowHeight <= 700 ? '' : "btn_position bottom-0 w-100"}>
                    <div className="row">
                        <div className="col-lg-12 col-xl-10 ms-auto">
                            <div className="row g-4">
                                <div className="col-lg-12 col-xl-6">
                                    {paymentCardData?.id === 1 &&
                                        <div className="btn_border border-black">
                                            <CustomButton className="text-dark w-100" onClick={() => setCardModal(true)}>
                                                {t("buttons.add")} /  {t("buttons.edit_credit_card")}
                                            </CustomButton>
                                        </div>
                                    }
                                </div>
                                <div className="col-lg-12 col-xl-6">
                                    <div className="btn_border border-black mb-4 mb-md-2 mb-lg-2 mb-xl-0">
                                        <CustomButton
                                            className="text-dark w-100"
                                            onClick={paymentCardData?.id === 1 ? handlePayAmount : paymentCardData?.id === 3 ? handleGooglePay : noFunc}
                                            style={{ background: 'var(--green)' }}
                                            loading={isLoading}
                                        >
                                            {t("buttons.pay")}
                                        </CustomButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <>
                    {paymentCardData?.id === 1 &&
                        <div className="d-flex justify-content-end mb-4">
                            <div className={i18n.language === "hi" || i18n.language === "es" ? "btn_border px-5" : "btn_border"}>
                                <CustomButton
                                    style={{ background: `var(--black)` }}
                                    onClick={() => setCardModal(true)}
                                >
                                    <span>{t("buttons.add")} Card</span>
                                </CustomButton>
                            </div>
                        </div>
                    }
                    <div className="d-flex justify-content-end">
                        <div className="btn_border">
                            <CustomButton
                                onClick={paymentCardData?.id === 1 ? handlePayAmount : paymentCardData?.id === 3 ? handleGooglePay : noFunc}
                                style={{ background: `var(--blue)` }}
                                loading={isLoading}
                            >
                                {t("buttons.pay")}
                            </CustomButton>
                        </div>
                    </div>
                </>
            }

            {/* Payment modal */}
            <Modal
                open={cardModal}
                footer={null}
                onCancel={() => setCardModal(false)}
                closeIcon={<img src={Assets.closeIcon} alt="" />}
                width={600}
            >
                <h4 className="medium_font fw-bold mt-2 mb-md-4 mb-lg-4">Add credit card</h4>
                <PaymentModal setCardModal={setCardModal} cardId={listCard?.id} />
            </Modal>

            {/* Payment Success modal */}
            <PaymentSuccessModal paymentSuccessModal={paymentSuccessModal} setPaymentSuccessModal={setPaymentSuccessModal} planType={planType} />

            {/* upgradation modal */}
            <Modal
                title={<img src={Assets.darkLogo} alt="" width={180} />}
                open={upgradeModal}
                onCancel={() => setUpgradeModal(false)}
                closeIcon={<img src={Assets.closeIcon} alt="" />}
                centered
                footer=''
            >
                <h6 className="medium_font fw-medium mt-4">
                    You cannot downgrade your subscription from the current plan. If you wish to downgrade, please cancel your current subscription first. You can, however, upgrade to a higher-tier plan at any time.
                </h6>
            </Modal>
        </>
    );
};

export default SelectPayment;
