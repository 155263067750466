import { useEffect, useState } from "react";
import { Layout } from "antd";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Assets } from "utils/Assets";
import { Icons } from "utils/Icons";
import { handleEnterKeyDown } from "utils/Constant";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useUserDetailQuery } from "../redux/features/settings/settingApi";
import { settingData, userData } from "../redux/features/common/commonSlice";
import { useListTeamQuery } from "../redux/features/myplan/planApi";
import { ProgressBar, Step } from "react-step-progress-bar";
import { cycleNumber } from "../redux/features/myplan/planSlice";
import CustomButton from "components/CustomButton";
import CreateTeamModal from "components/CreateTeamModal";
import LogoutModal from "components/LogoutModal";
const { Header, Sider, Content, Footer } = Layout;

const Authrised = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { t } = useTranslation();
    const [teamModal, setTeamModal] = useState(false);
    const [activeIndex, setActiveIndex] = useState("/my-plan");
    const [logoutModal, setLogoutModal] = useState(false);
    const { data: userDetail } = useUserDetailQuery();
    const { data } = useListTeamQuery();
    const reduxData = useSelector(state => state);
    const token = reduxData?.common?.user_token?.accessToken;
    const settingHeader = reduxData?.common?.setting_data;
    const planHeader = reduxData?.plan?.team_detail;
    const cycleNo = reduxData?.plan?.cycle_number;
    const sessionHeader = reduxData?.plan?.session_detail;
    const cycleSteps = reduxData?.plan?.cycle_steps;
    const drillHeader = reduxData?.plan?.drill_detail;
    const universalHeader = reduxData?.universal?.universal_detail;

    const percentValues = [
        cycleSteps?.cycle?.cycle_1_percent,
        cycleSteps?.cycle?.cycle_2_percent,
        cycleSteps?.cycle?.cycle_3_percent,
    ];

    const totalPercent = percentValues.reduce((total, percent) => total + (percent || 0), 0);
    const averagePercent = totalPercent / percentValues.length;

    const sideList = [
        {
            key: 'myplan',
            routes: '/my-plan',
            icon: <Icons.FaCalendarAlt size={20} />,
            label: <>{t("sider.my_plan")}</>,
        },
        {
            key: 'universals',
            routes: '/universals',
            icon: <Icons.FaEarthAmericas size={20} />,
            label: <>{t("sider.universals")}</>,
        },
        {
            key: 'settings',
            routes: '/settings',
            icon: <Icons.IoSettingsSharp size={20} />,
            label: <>{t("sider.settings")}</>,
        },

    ];

    useEffect(() => {
        setActiveIndex(location.pathname);
        if (location.pathname === `/session/${id}` || location.pathname === `/drills/${id}` || location.pathname === `/drill-details/${id}`) {
            setActiveIndex('/my-plan')
        } else if (location.pathname == `/universal-detail/${id}`) {
            setActiveIndex('/universals')
        }
    }, [activeIndex, { ...location }]);

    useEffect(() => {
        if (location.pathname !== "/settings") {
            dispatch(settingData({}))
        }
    }, [location]);

    useEffect(() => {
        dispatch(userData(userDetail?.data))
    }, [userDetail]);

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    }, [token]);

    useEffect(() => {
        if (location.pathname != `/session/${id}`) {
            dispatch(cycleNumber(1))
        }
    }, [location]);

    return (
        <>
            <div className="bg-black p-md-3 p-lg-3">
                <Layout className="bg-black">
                    <aside>
                        <Sider theme="light" breakpoint="md" className='siderbar_div d-none d-md-block d-lg-block position-relative'>
                            <div className='logo_div'>
                                <img src={Assets.darkLogo} alt="logo" width="80%" />
                                <hr className="my-0 w-75 mx-auto" />
                            </div>

                            <div className="route_list ps-4">
                                {sideList?.map((item) => {
                                    return (
                                        <Link to={item.routes} key={item.key}>
                                            <>
                                                <div className="d-flex align-items-center gap-3 my-4" onClick={() => setActiveIndex(item.routes)} role="button" tabIndex={0} onKeyDown={handleEnterKeyDown(item.routes, setActiveIndex)}>
                                                    <span className="route_icon animated-image" style={{ background: activeIndex == item.routes ? `var(--green)` : `var(--btn-gray)` }}>{item.icon}</span>
                                                    <span className="small_font text-black text-capitalize">{item.label}</span>
                                                </div>
                                            </>
                                        </Link>
                                    )
                                })}
                                <div className="position-absolute" style={{ bottom: 20, left: 50 }}>
                                    <CustomButton
                                        icon={<Icons.BiLogOutCircle size={20} color={`black`} />}
                                        style={{ background: `var(--btn-gray)` }}
                                        className="w-auto h-auto py-2 px-4 text-black"
                                        onClick={() => setLogoutModal(true)}
                                    >
                                        {t("buttons.logout")}
                                    </CustomButton>
                                </div>
                            </div>
                        </Sider>
                    </aside>
                    <Layout className="bg-black">
                        <Header className='header_div w-100'>
                            {location?.pathname === '/my-plan' ?
                                <>
                                    <div className="">
                                        <h1 className="medium_font text-white fs-4 fw-medium mb-2 mb-lg-1">{t("header.my_plan")}</h1>
                                        <h6 className="small_font text-white fw-medium">{t("header.my_teams")}:<span style={{ color: `var(--green)` }}>{data?.data?.length}</span></h6>
                                    </div>
                                    <div className="d-none d-sm-block d-md-block d-lg-block text-end">
                                        <div className="btn_border d-flex">
                                            <CustomButton
                                                onClick={() => setTeamModal(true)}
                                                style={{ background: `var(--green)`, color: `var(--black)`, width: 'auto' }}
                                            >
                                                {t("buttons.create_new_team")}
                                            </CustomButton>
                                        </div>
                                    </div>
                                </>
                                :
                                location.pathname === `/session/${id}` || location.pathname === `/drills/${id}` || location.pathname === `/drill-details/${id}` ?
                                    <>
                                        <div className="d-flex align-items-center gap-1">
                                            <Icons.IoIosArrowBack onClick={() => navigate(-1)} size={30} color={`var(--white)`} style={{ cursor: 'pointer' }} />
                                            <div>
                                                <h1 className="medium_font text-white fs-4 fw-medium mb-2 mb-lg-1">{planHeader?.team_name}</h1>
                                                <h6 className="small_font text-white fw-medium">
                                                    <span>{t("header.plan")}:<span style={{ color: `var(--green)` }}>{planHeader?.plan_id?.plan_name}</span> / </span>
                                                    <span>{t("header.cycle")}:<span style={{ color: `var(--green)` }}>{cycleNo}</span></span>
                                                    {location.pathname === `/drills/${id}` || location.pathname === `/drill-details/${id}` ?
                                                        <>
                                                            <span> / {t("header.session")}:<span style={{ color: `var(--green)` }}>{sessionHeader?.session_no}</span></span>
                                                            {location.pathname === `/drill-details/${id}` && <span> / {t("header.drill")}:<span style={{ color: `var(--green)` }}>{drillHeader?.drill_no}</span></span>}
                                                        </>
                                                        : ""
                                                    }
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="progress_div d-none d-sm-block d-md-block d-lg-block">
                                            {/* <img src={Assets.stepsImg} alt="" className="cycle_progress_bar" /> */}
                                            {/* <Steps current={1} percent={50} labelPlacement="vertical" items={cycleItems} /> */}

                                            <ProgressBar percent={averagePercent} filledBackground="var(--green)">
                                                {percentValues.map((percent, index) => (
                                                    <Step key={index}>
                                                        {({ accomplished }) => (
                                                            <div
                                                                style={{ borderColor: percent === 100 ? `var(--green)` : `var(--white)` }}
                                                                className={`indexedStep ${accomplished ? "accomplished" : ""} outside_border`}
                                                            >
                                                                <div
                                                                    style={{ background: percent === 100 ? `var(--green)` : `var(--white)` }}
                                                                    className="cycle_number_div"
                                                                >
                                                                    {index + 1}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Step>
                                                ))}
                                            </ProgressBar>

                                            <div className="d-flex justify-content-between mt-4">
                                                <h6 className="text-white ">{cycleSteps?.cycle?.cycle_1_percent === 100 ? "Completed" : (cycleSteps?.cycle?.cycle_1_percent < 100 && cycleSteps?.cycle?.cycle_1_percent > 0) ? "In Progress" : "Not Started"}</h6>
                                                <h6 className="text-white">{cycleSteps?.cycle?.cycle_2_percent === 100 ? "Completed" : (cycleSteps?.cycle?.cycle_2_percent < 100 && cycleSteps?.cycle?.cycle_2_percent > 0) ? "In Progress" : "Not Started"}</h6>
                                                <h6 className="text-white">{cycleSteps?.cycle?.cycle_3_percent === 100 ? "Completed" : (cycleSteps?.cycle?.cycle_3_percent < 100 && cycleSteps?.cycle?.cycle_3_percent > 0) ? "In Progress" : "Not Started"}</h6>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    location.pathname === '/universals' ?
                                        <h1 className="medium_font text-white fs-4 fw-medium mb-1">{t("header.universals")}</h1>
                                        :
                                        location.pathname === `/universal-detail/${id}` ?
                                            <div className="d-flex align-items-center gap-1">
                                                <Icons.IoIosArrowBack onClick={() => navigate('/universals')} size={30} color={`var(--white)`} style={{ cursor: 'pointer' }} />
                                                <h1 className="medium_font text-white fs-4 fw-medium mb-1">{universalHeader}</h1>
                                            </div>
                                            :
                                            location.pathname === '/settings' ?
                                                <div className="d-flex align-items-center justify-content-between w-100">
                                                    <div className="d-flex align-items-center gap-1 w-100">
                                                        {Object.keys(settingHeader).length > 0 && <Icons.IoIosArrowBack onClick={() => { navigate('/settings'); dispatch(settingData({})) }} size={30} color={`var(--white)`} style={{ cursor: 'pointer' }} />}
                                                        <div className="d-flex align-items-center justify-content-between w-100">
                                                            <div>
                                                                <h1 className="medium_font text-white fs-4 fw-medium mb-2 mb-lg-1">{t("header.settings")}</h1>
                                                                <h6 className="small_font text-white fw-medium">{settingHeader?.title}</h6>
                                                            </div>
                                                            <div className="d-block d-md-none d-lg-none" >
                                                                <CustomButton
                                                                    icon={<Icons.BiLogOutCircle size={20} color={`black`} />}
                                                                    style={{ background: `var(--btn-gray)` }}
                                                                    className="w-auto h-auto py-2 px-3 text-black"
                                                                    onClick={() => setLogoutModal(true)}
                                                                >
                                                                    {t("buttons.logout")}
                                                                </CustomButton>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="d-none d-md-block d-lg-block">
                                                        {settingHeader?.id === 3 &&
                                                            <CustomButton
                                                                style={{ background: `var(--green)`, color: `var(--black)`, width: 'auto', borderRadius: 50, height: 45 }}
                                                            >
                                                                {t("header.unused_plans")}: {userDetail?.data?.teams_unused}
                                                            </CustomButton>
                                                        }
                                                    </div>
                                                </div>
                                                : ""
                            }
                        </Header>
                        <Content className='main_content'>
                            <Outlet />
                        </Content>
                        <footer className="d-md-none d-lg-none">
                            <Footer className='footerStyle'>
                                {sideList?.map((item) => {
                                    return (
                                        <Link to={item.routes} key={item.key}>
                                            <>
                                                <div className="d-flex flex-column align-items-center" onClick={() => setActiveIndex(item.routes)} role="button" tabIndex={0} onKeyDown={handleEnterKeyDown(item.routes, setActiveIndex)}>
                                                    <span className="route_icon mb-1" style={{ background: activeIndex == item.routes ? `var(--green)` : `var(--btn-gray)` }}>{item.icon}</span>
                                                    <dt className="small_font text-black">{item.label}</dt>
                                                </div>
                                            </>
                                        </Link>
                                    )
                                })}
                            </Footer>
                        </footer>
                    </Layout>
                </Layout>
            </div>

            {/* Team modal */}
            <CreateTeamModal teamModal={teamModal} setTeamModal={setTeamModal} />

            {/* Logout modal */}
            <LogoutModal logoutModal={logoutModal} setLogoutModal={setLogoutModal} />
        </>
    )
}

export default Authrised;
