import { useState } from "react";
import { Form, Modal } from "antd";
import { Assets } from "utils/Assets";
import { useTranslation } from "react-i18next";
import { useChangePasswordMutation, useUpdateUserDetailMutation } from "../../redux/features/settings/settingApi";
import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import Toast from "utils/Toast";
import PasswordInput from "components/PasswordInput";
import { useSelector } from "react-redux";

const AccounInformation = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [accountInfo, setAccountInfo] = useState(false);
    const [emailScreen, setEmailScreen] = useState(false);
    const [passwordScreen, setPasswordScreen] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [userName, setUserName] = useState('');
    const [updateUser, { isLoading }] = useUpdateUserDetailMutation();
    const [changePassword, { isLoading: passwordLoader }] = useChangePasswordMutation();
    const userDetail = useSelector(state => state?.common?.user_data);

    const handleOpenModal = (type) => {
        setAccountInfo(true)
        if (type == 'user_name') {
            setEmailScreen(false)
            setUserName(userDetail?.full_name)
        } else {
            setEmailScreen(true)
        }
    }

    const handleUpdateProfile = async () => {
        try {
            const res = await updateUser({ full_name: userName }).unwrap();
            if (res) {
                setAccountInfo(false)
                setUserName('')
            }
        } catch (error) {
            Toast("e", error?.data?.message ?? error?.status);
        }
    };

    const handleChangePassword = async (formVal) => {
        if (formVal?.new_password !== formVal?.c_password) {
            return Toast('e', 'Both password does not match');
        }
        try {
            const payload = {
                old_password: formVal.old_password,
                new_password: formVal.new_password,
            };
            const res = await changePassword(payload).unwrap();
            if (res) {
                setAccountInfo(false);
                form.resetFields();
                Toast("s", res?.message);
            }
        } catch (error) {
            Toast("e", error?.data?.message ?? error?.status);
        }
    }

    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div className="text_content">
                    <dt className="small_font mb-1" style={{ color: 'var(--text-gray)' }}>{t("auth.email")}:</dt>
                    <h1 className="medium_font">{userDetail?.email}</h1>
                </div>
            </div>
            <hr />
            <div className="d-flex align-items-center justify-content-between">
                <div className="text_content">
                    <dt className="small_font mb-1" style={{ color: 'var(--text-gray)' }}>{t("auth.full_name")}:</dt>
                    <h1 className="medium_font">{userDetail?.full_name}</h1>
                </div>
                <CustomButton onClick={() => handleOpenModal('user_name')} type="ghost" className="route_icon bg-white h-auto w-auto"><img src={Assets.editIcon} alt="" width='18px' /></CustomButton>
            </div>
            <hr />
            <div className="d-flex align-items-center justify-content-between">
                <div className="text_content">
                    <dt className="small_font mb-1" style={{ color: 'var(--text-gray)' }}>{t("settings.account_info.password")}:</dt>
                    <h2 className="medium_font">xxxxxxxx</h2>
                </div>
                <CustomButton onClick={() => handleOpenModal('password')} type="ghost" className="route_icon bg-white h-auto w-auto"><img src={Assets.editIcon} alt="" width='18px' /></CustomButton>
            </div>

            <div className="text-end btn_position">
                <CustomButton onClick={() => setDeleteModal(true)} className="text-black border border-2 border-black w-auto">
                    {t("settings.account_info.delete_my_account")}
                </CustomButton>
            </div>

            {/* username and password modal */}
            <Modal open={accountInfo} footer='' centered closeIcon={<img src={Assets.closeIcon} alt="" />} onCancel={() => { setAccountInfo(false); setPasswordScreen(false); setUserName(''); form.resetFields(); }}>
                <h4 className="medium_font fw-bold mt-3">{!emailScreen ? t("settings.account_info.edit_username") : t("settings.account_info.change_password")}</h4>
                {!emailScreen ?
                    <div className="mt-4">
                        <dt className="small_font mb-2" style={{ color: 'var(--text-gray)' }}>{t("settings.account_info.username")}:</dt>
                        <CustomInput value={userName} onChange={(e) => setUserName(e.target.value)} className="border-black text-dark" />
                        <div className="d-flex gap-4 mt-4">
                            <div className="btn_border border-black w-50">
                                <CustomButton
                                    onClick={() => handleUpdateProfile()}
                                    style={{ background: `var(--green)`, color: `var(--black)`, width: '100%' }}
                                    loading={isLoading}
                                >
                                    {t("buttons.save")}
                                </CustomButton>
                            </div>
                            <div className="btn_border border-black w-50">
                                <CustomButton
                                    onClick={() => { setAccountInfo(false); setUserName('') }}
                                    style={{ background: `var(--btn-gray)`, color: `var(--black)`, width: '100%' }}
                                >
                                    {t("buttons.cancel")}
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                    :
                    !passwordScreen ?
                        // <div className="mt-4">
                        //     <dt className="small_font mb-2" style={{ color: 'var(--text-gray)' }}>{t("settings.account_info.email_for_password")}</dt>
                        //     <CustomInput type="email" className="border-black text-dark" />
                        //     <div className="d-flex gap-4 mt-4">
                        //         <div className="btn_border border-black w-50">
                        //             <CustomButton
                        //                 onClick={() => setPasswordScreen(true)}
                        //                 style={{ background: `var(--green)`, color: `var(--black)`, width: '100%' }}
                        //             >
                        //                 {t("buttons.send")}
                        //             </CustomButton>
                        //         </div>
                        //         <div className="btn_border border-black w-50">
                        //             <CustomButton
                        //                 onClick={() => { setAccountInfo(false) }}
                        //                 style={{ background: `var(--btn-gray)`, color: `var(--black)`, width: '100%' }}
                        //             >
                        //                 {t("buttons.cancel")}
                        //             </CustomButton>
                        //         </div>
                        //     </div>
                        // </div>
                        <Form form={form} onFinish={handleChangePassword} layout="vertical" className="mt-4">
                            <Form.Item name='old_password' label={<dt className="small_font" style={{ color: 'var(--text-gray)' }}>{t("settings.account_info.old_password")}</dt>}>
                                <PasswordInput className='border-black text-dark' iconColor={`var(--black)`} />
                            </Form.Item>

                            <Form.Item name='new_password' label={<dt className="small_font" style={{ color: 'var(--text-gray)' }}>{t("settings.account_info.new_password")}</dt>}>
                                <PasswordInput className='border-black text-dark' iconColor={`var(--black)`} />
                            </Form.Item>

                            <Form.Item name='c_password' label={<dt className="small_font" style={{ color: 'var(--text-gray)' }}>{t("settings.account_info.confirm_new_password")}</dt>}>
                                <PasswordInput className='border-black text-dark' iconColor={`var(--black)`} />
                            </Form.Item>

                            <Form.Item className="mb-0">
                                <div className="d-flex gap-3">
                                    <div className="btn_border border-black w-100">
                                        <CustomButton
                                            htmlType='submit'
                                            className={` w-100`}
                                            style={{ background: `var(--green)`, color: `var(--black)` }}
                                            loading={passwordLoader}
                                        >
                                            {t("buttons.done")}
                                        </CustomButton>
                                    </div>
                                    <div className="btn_border border-black w-100">
                                        <CustomButton
                                            onClick={() => { setAccountInfo(false); setPasswordScreen(false); form.resetFields(); }}
                                            className={` w-100`}
                                            style={{ background: `var(--btn-gray)`, color: `var(--black)` }}
                                        >
                                            {t("buttons.cancel")}
                                        </CustomButton>
                                    </div>
                                </div>
                            </Form.Item>
                        </Form>
                        :
                        ""
                }
            </Modal>

            {/* delete account Modal */}
            <Modal open={deleteModal} footer='' centered closeIcon={<img src={Assets.closeIcon} alt="" />} onCancel={() => { setDeleteModal(false) }}>
                <h4 className="medium_font fw-bold mt-2"> {t("settings.account_info.delete_my_account")}</h4>
                <h6 className="small_font my-4 text-dark">{t("settings.account_info.email_us")} {`premierfutbol@gmail.com`} {t("settings.account_info.delete_your_account")}.</h6>
                <div className="btn_border border-black">
                    <CustomButton
                        onClick={() => { setAccountInfo(false) }}
                        style={{ background: `var(--green)`, color: `var(--black)`, width: '100%' }}
                    >
                        {t("buttons.done")}
                    </CustomButton>
                </div>
            </Modal>
        </>
    )
}

export default AccounInformation;
