import { Input } from 'antd';
import PropTypes from 'prop-types';

const CustomInput = ({
    onChange,
    value,
    className = '',
    style,
    suffix,
    allowClear = false,
    readOnly = false,
    prefix,
    variant = 'borderless',
    maxLength = 100,
    min,
    type = 'text',
    required = false,
    placeholder = 'Enter here...',
}) => {


    return (
        <Input
            onChange={onChange}
            value={value}
            type={type}
            className={`common_input ${className}`}
            style={style}
            min={min}
            maxLength={maxLength}
            readOnly={readOnly}
            allowClear={allowClear}
            variant={variant}
            suffix={suffix}
            prefix={prefix}
            required={required}
            placeholder={placeholder}
        />
    );
};

CustomInput.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.string,
    ]),
    className: PropTypes.string,
    style: PropTypes.object,
    placeholder: PropTypes.string,
    suffix: PropTypes.element,
    prefix: PropTypes.element,
    variant: PropTypes.string,
    maxLength: PropTypes.number,
    min: PropTypes.number,
    ref: PropTypes.element,
    readOnly: PropTypes.bool,
    allowClear: PropTypes.bool,
};

export default CustomInput;
