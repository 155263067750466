export const languageList = [
    {
      "label": <dt>English (US)</dt>,
      "value": "en"
    },
    {
      "label": <dt>Spanish (ES)</dt>,
      "value": "es"
    },
    {
      "label": <dt>Hindi (HI)</dt>,
      "value": "hi"
    },
    {
      "label": <dt>Indonesian (ID)</dt>,
      "value": "id"
    }
  ]
  
  export const cycleItems = [
    {
      title: '',
      description: <dt className="text-white">Cycle Completed</dt>,
      icon: <div style={{ height: 32, width: 32, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><dt className="small_font text-black">1</dt></div>,
    },
    {
      title: '',
      description: <dt className="text-white">Cycle In Progress</dt>,
      icon: <div style={{ height: 32, width: 32, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><dt className="small_font text-black">2</dt></div>,
    },
    {
      title: '',
      description: <dt className="text-white">Cycle Awaiting</dt>,
      icon: <div style={{ height: 32, width: 32, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><dt className="small_font text-black">3</dt></div>,
    },
  ];
  