import { useEffect, useState } from "react";
import { Modal } from "antd";
import { Assets } from "utils/Assets";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePromoCodeMutation } from "../../redux/features/common/commonApi";
import { useDispatch, useSelector } from "react-redux";
import { commonData, promoCodeData } from "../../redux/features/common/commonSlice";
import { useListSubscriptionQuery } from "../../redux/features/payment/paymentApi";
import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import useWindowHeight from "utils/useWindowHeight";
import Toast from "utils/Toast";

const SelectTeam = () => {
    const windowHeight = useWindowHeight();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [quantity, setQuantity] = useState(1);
    const [clubModal, setClubModal] = useState(false);
    const [clubCodeScreen, setClubCodeScreen] = useState(false);
    const [promoValue, setPromoValue] = useState('');
    // const { data } = useCommonContentQuery();
    const { data: listSubscription } = useListSubscriptionQuery();
    const [promoCode, { isLoading }] = usePromoCodeMutation();
    const promoData = useSelector(state => state?.common?.promocode_data);

    const subsObj = listSubscription?.data?.find(item => item?.name === quantity?.toString());
    const totalAmount = subsObj?.price;
    const discountAmount = totalAmount * (promoData?.discount_value / 100);
    const discountedAmount = totalAmount - discountAmount;

    console.log(discountAmount,"discount")
    console.log(discountedAmount,"discountedAmountdiscountedAmount")

    // console.log(subsObj,'subsObjsubsObjsubsObj')

    const increase = () => {
        if (quantity < listSubscription?.data?.length) {
            setQuantity(quantity + 1);
        }
    };

    const decrease = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const handleApplyPromoCode = async () => {
        try {
            const res = await promoCode({ promocode: promoValue, no_of_teams: quantity.toString() }).unwrap();
            if (res) {
                Toast("s", res?.message);
                dispatch(promoCodeData(res?.data));
                setPromoValue('');
                setClubModal(false);
            }
        } catch (error) {
            Toast("e", error?.data?.message ?? error?.status);
        }
    }

    const handleNext = () => {
        if (promoData && Object.keys(promoData).length > 0) {
            navigate('/payment');
        } else {
            setClubModal(true);
        }
    }

    useEffect(() => {
        dispatch(commonData({ discountedAmount: discountedAmount, subscriptionData: subsObj }))
    }, [quantity, discountedAmount]);

    return (
        <>
            <div className="extra_margin">
                <div className="d-flex align-items-start justify-content-between mb-4 mb-xl-5">
                    <h1 className="big_font text-white">{t('teams.select_teams')}</h1>
                    <img src={Assets.logoBall} alt="" width='60px' className="d-md-none d-lg-none d-block" />
                </div>
                <h2 className="medium_font text-white">{t('teams.team_cost')}:</h2>
                <h3 className="big_font" style={{ color: `var(--green)` }}>${subsObj?.price}<span className="medium_font text-white">/{t('teams.month')}</span></h3>
                <div className="my-5">
                    <dt className="small_font mb-2">{t('teams.select_teams_info')}</dt>
                    <div className="btn_border d-flex align-items-center">
                        <CustomButton
                            onClick={decrease}
                            disabled={quantity <= 1}
                            style={{ background: `var(--green)`, color: `var(--black)`, padding: '0 1.3rem', width: 'auto' }}
                        >
                            <dt className="small_font text-black fs-4">-</dt>
                        </CustomButton>
                        <CustomInput
                            value={quantity}
                            readOnly
                            className="border-0 text-center fs-4"
                        />
                        <CustomButton
                            onClick={increase}
                            disabled={quantity >= listSubscription?.data?.length}
                            style={{ background: `var(--green)`, color: `var(--black)`, padding: '0 1.3rem', width: 'auto' }}
                        >
                            <dt className="small_font text-black">+</dt>
                        </CustomButton>
                    </div>
                </div>
                <div className="d-flex align-items-end justify-content-between text-end">
                    <h4 className="small_font text-white">{t('teams.total')}:</h4>
                    <h5 className="small_font text-white"> {quantity} {t('teams.teams')} <br />
                        {promoData && Object.keys(promoData).length > 0 &&
                            <>
                                <span style={{ color: `var(--yellow-normal)` }}>
                                    {t('teams.your_discount')} {promoData?.discount_value}%
                                </span>
                                <br />
                            </>
                        }
                        ${discountedAmount === 0 || discountedAmount ? discountedAmount : subsObj?.price}/{t('teams.month')}
                    </h5>
                </div>
            </div>
            <div className={windowHeight <= 800 ? 'd-flex justify-content-end extra_margin' : 'auth_btn_position'}>
                <div className="btn_border">
                    <CustomButton
                        onClick={handleNext}
                        style={{ background: `var(--blue)` }}
                    >
                        {t('buttons.next')}
                    </CustomButton>
                </div>
            </div>

            <Modal
                open={clubModal}
                footer=''
                className="club_modal"
                centered width={400}
                closeIcon={<img src={Assets.closeIcon} alt="" />}
                onCancel={() => { setClubModal(false); setClubCodeScreen(false); setPromoValue('') }}
            >
                <h6 className="medium_font fw-bold mt-3">{t("teams.do_you_have_club_code")}</h6>
                {!clubCodeScreen ?
                    <div className="d-flex gap-4 mt-4">
                        <div className="btn_border border-black w-50">
                            <CustomButton
                                onClick={() => setClubCodeScreen(true)}
                                style={{ background: `var(--green)`, color: `var(--black)`, width: '100%' }}
                            >
                                {t('buttons.yes')}
                            </CustomButton>
                        </div>
                        <div className="btn_border border-black w-50">
                            <CustomButton
                                onClick={() => navigate('/payment')}
                                style={{ background: `var(--btn-gray)`, color: `var(--black)`, width: '100%' }}
                            >
                                {t('buttons.no')}
                            </CustomButton>
                        </div>
                    </div>
                    :
                    <div className="mt-4">
                        <dt className="small_font mb-2 text-secondary">{t("teams.enter_code")}:</dt>
                        <CustomInput value={promoValue} onChange={(e) => setPromoValue(e.target.value)} className="border-black text-dark" />
                        {promoData && Object.keys(promoData).length > 0 && <h3 style={{ color: `var(--golden)` }} className="mt-4"> {t('teams.your_discount')} {promoData?.discount_value}%</h3>}
                        <div className="btn_border border-black mt-4">
                            <CustomButton
                                onClick={handleApplyPromoCode}
                                style={{ background: `var(--green)`, color: `var(--black)`, width: '100%' }}
                                loading={isLoading}
                            >
                                {t('buttons.next')}
                            </CustomButton>
                        </div>
                    </div>
                }
            </Modal>
        </>
    )
}

export default SelectTeam;
