import { useState } from "react";
import { Collapse } from "antd";
import { useParams } from "react-router-dom";
import { useUniversalDetailQuery } from "../../redux/features/universal/universalApi";
import { Assets } from "utils/Assets";
import { file_url } from "constant/url";
import { useTranslation } from "react-i18next";
import VideoModal from "components/VideoModal";
import { Icons } from "utils/Icons";
const { Panel } = Collapse;

const UniversalDetail = () => {
    const [planId, setPlanId] = useState('');
    const [videoModal, setVideoModal] = useState(false);
    const { t } = useTranslation();
    const { id } = useParams();
    const { data } = useUniversalDetailQuery(id);
    const universalDetail = data?.data?.plan_ids?.find(item => item?._id === planId);

    const CustomExpandIcon = ({ isActive }) => (
        <div className="mt-5">
            {isActive ? <Icons.IoIosArrowDown size={25} /> : <Icons.IoIosArrowForward size={25} />}
        </div>
    );

    return (
        <>
            <div className="d-none d-lg-block">
                <div className="row g-3 g-lg-5">
                    <div className="col-lg-5 col-xl-6">
                        {data?.data?.plan_ids?.map((item) => {
                            return (
                                <div role="button" tabIndex={0} onKeyDown={() => ''} onClick={() => setPlanId(item?._id)} className="common_card align-items-start mb-3" key={item?._id} style={{ padding: '0.8rem', border: planId === item?._id && '2px solid black' }}>
                                    <div className="card_content d-flex align-items-center gap-3">
                                        <div
                                            style={{
                                                backgroundImage: `url(${file_url}${item?.plan_media})`,
                                                backgroundSize: 'cover',
                                            }}
                                        />
                                        <div className="text_content">
                                            <h1 className="medium_font fw-bold">{item?.plan_description}</h1>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {planId &&
                        <div className="col-lg-7 col-xl-6">
                            <div className="card_content">
                                <div
                                    style={{
                                        backgroundImage: `url(${file_url}${universalDetail.plan_media})`,
                                        backgroundSize: 'cover',
                                        height: '100px',
                                        width: '100px'
                                    }}
                                />
                                <div className="text_content mt-3">
                                    <h4 className="medium_font">{universalDetail?.plan_description}</h4>
                                    <hr />
                                    <div className="my-3">
                                        <h5 className="so_small_font">{t("universal.definition")}:</h5>
                                        <span className="so_small_font fw-normal">{universalDetail?.plan_definition}</span>
                                        <hr />
                                    </div>
                                    <div className="my-3">
                                        <h5 className="so_small_font">{t("universal.related_concepts")} {universalDetail?.plan_name}:</h5>
                                        <ul>
                                            {universalDetail?.related_concepts?.map((item, indx) => (
                                                <li key={indx} className="">{item}</li>
                                            ))}
                                        </ul>
                                        <hr />
                                    </div>
                                    <div className="my-3">
                                        <h5 className="so_small_font">{t("universal.video_explanation")} {universalDetail?.plan_name} {t("universal.concepts")}:</h5>
                                        <div className="video_position" role="button" tabIndex={0} onKeyDown={() => '_'} onClick={() => setVideoModal(true)}>
                                            <video className="video_style w-100" src={`${file_url}${universalDetail?.video_explanation}`}>
                                                <track kind="captions" />
                                            </video>
                                            <img src={Assets.playBtn} alt="" width='50px' className="p-0  play_btn_position" />
                                        </div>
                                        <hr />
                                    </div>
                                    {universalDetail?.session_focused_on_universal?.length > 0 &&
                                        <div className="my-3">
                                            <h5 className="so_small_font">{t("universal.sessions_focused")}</h5>
                                            <ul>
                                                {universalDetail?.session_focused_on_universal?.map((item, indx) => (
                                                    <li key={indx} className="">{t("header.session")} {item?.session_no}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <Collapse
                ghost
                accordion
                expandIconPosition="end"
                bordered={false}
                expandIcon={({ isActive }) => <CustomExpandIcon isActive={isActive} />}
                className="d-block d-lg-none"
            >
                {data?.data?.plan_ids?.map((item) => (
                    <Panel
                        className="bg-white rounded-4 mb-3 border-light"
                        header={(
                            <div role="button" tabIndex={0} onKeyDown={() => ''} onClick={() => setPlanId(item?._id)} className="align-items-start" key={item?._id}>
                                <div className="card_content d-flex align-items-center gap-3">
                                    <div
                                        style={{
                                            backgroundImage: `url(${file_url}${item?.plan_media})`,
                                            backgroundSize: 'cover',
                                        }}
                                    />
                                    <div className="text_content">
                                        <h1 className="medium_font fw-bold">{item?.plan_description}</h1>
                                    </div>
                                </div>
                            </div>
                        )}
                        key={item?._id}
                    >
                        {planId === item?._id && (
                            <div className="card_content">
                                <div
                                    style={{
                                        backgroundImage: `url(${file_url}${universalDetail.plan_media})`,
                                        backgroundSize: 'cover',
                                        height: '100px',
                                        width: '100px',
                                    }}
                                />
                                <div className="text_content mt-3">
                                    <h4 className="medium_font">{universalDetail?.plan_description}</h4>
                                    <hr />
                                    <div className="my-3">
                                        <h5 className="so_small_font">{t("universal.definition")}:</h5>
                                        <span className="so_small_font fw-normal">{universalDetail?.plan_definition}</span>
                                        <hr />
                                    </div>
                                    <div className="my-3">
                                        <h5 className="so_small_font">{t("universal.related_concepts")} {universalDetail?.plan_name}:</h5>
                                        <ul>
                                            {universalDetail?.related_concepts?.map((relatedItem, indx) => (
                                                <li key={indx}>{relatedItem}</li>
                                            ))}
                                        </ul>
                                        <hr />
                                    </div>
                                    <div className="my-3">
                                        <h5 className="so_small_font">{t("universal.video_explanation")} {universalDetail?.plan_name} {t("universal.concepts")}:</h5>
                                        <div className="video_position" role="button" tabIndex={0} onKeyDown={() => '_'} onClick={() => setVideoModal(true)}>
                                            <video className="video_style w-100" src={`${file_url}${universalDetail?.video_explanation}`}>
                                                <track kind="captions" />
                                            </video>
                                            <img src={Assets.playBtn} alt="" width='50px' className="p-0 play_btn_position" />
                                        </div>
                                        <hr />
                                    </div>
                                    {universalDetail?.session_focused_on_universal?.length > 0 && (
                                        <div className="my-3">
                                            <h5 className="so_small_font">{t("universal.sessions_focused")}</h5>
                                            <ul>
                                                {universalDetail?.session_focused_on_universal?.map((sessionItem, indx) => (
                                                    <li key={indx}>{t("header.session")} {sessionItem?.session_no}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </Panel>
                ))}
            </Collapse>

            {/* video modal */}
            <VideoModal videoModal={videoModal} setVideoModal={setVideoModal} videosrc={universalDetail?.video_explanation} />
        </>
    )
}

export default UniversalDetail;
