import { createSlice } from '@reduxjs/toolkit';

const planSlice = createSlice({
    name: 'plan',
    initialState: {
        team_detail: {},
        cycle_number: 0,
        cycle_steps: {},
        session_detail: {},
        drill_detail: {},
    },
    reducers: {
        teamData: (state, action) => {
            state.team_detail = action.payload;
        },
        cycleNumber: (state, action) => {
            state.cycle_number = action.payload;
        },
        cycleStepsData: (state, action) => {
            state.cycle_steps = action.payload;
        },
        sessionDetail: (state, action) => {
            state.session_detail = action.payload;
        },
        drillDetails: (state, action) => {
            state.drill_detail = action.payload;
        },
    },
});

export const { teamData, cycleNumber, cycleStepsData, sessionDetail, drillDetails } = planSlice.actions;
export default planSlice.reducer;