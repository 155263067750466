import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { baseApi } from "../redux/app/baseApi";
import { useNavigate } from "react-router-dom";
import CustomButton from "./CustomButton";

const LogoutModal = ({ logoutModal, setLogoutModal }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        navigate('/login', { replace: true });
        localStorage.clear();
        dispatch(baseApi.util.resetApiState());
    };


    return (
        <>
            <Modal open={logoutModal} closable={false} footer='' centered>
                <div className="px-lg-2 py-md-4 py-lg-4 text-center">
                    <h5 className='big_font fw-bolder fs-3'>{t('my_plan.sure')}</h5>
                    <p className='small_font fw-normal text-dark'>{t('my_plan.sure_logout')}</p>
                    <div className="d-flex gap-2 gap-md-3 gap-lg-3 mt-4 mt-md-5 mt-lg-5">
                        <div className="btn_border border-black w-100">
                            <CustomButton
                                onClick={() => handleLogout()}
                                style={{ background: `var(--green)`, color: `var(--black)`, width: '100%', padding: 0 }}
                            >
                                {t("buttons.yes").toUpperCase()}, LOGOUT
                            </CustomButton>
                        </div>
                        <div className="btn_border border-black w-100">
                            <CustomButton
                                style={{ background: `var(--btn-gray)`, color: `var(--black)`, width: '100%', padding: 0 }}
                                onClick={() => setLogoutModal(false)}
                            >
                                {t("buttons.cancel").toUpperCase()}
                            </CustomButton>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default LogoutModal;
