import { useState } from "react";
import { Form } from "antd";
import { Assets } from "utils/Assets";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useForgotPasswordMutation, useResetPasswordMutation } from "../../redux/features/auth/authApi";
import { useNavigate } from "react-router-dom";
import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import Toast from "utils/Toast";
// import VerificationInput from "react-verification-input";
import PasswordInput from "components/PasswordInput";
import VerifyScreen from "./VerifyScreen";

const Forgot = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    // const [otpVal, setOtpVal] = useState('');
    const [email, setEmail] = useState('');
    const [activeScreen, setActiveScreen] = useState('forgot');
    const [forgot, { isLoading }] = useForgotPasswordMutation();
    // const [verify, { isLoading: verifyLoader }] = useVerifyOtpMutation();
    const [reset, { isLoading: resetLoader }] = useResetPasswordMutation();
    const langCode = useSelector(state => state?.common?.lang_code);

    const handleForgot = async (formVal) => {
        setEmail(formVal?.email)
        try {
            const payload = { email: formVal?.email?.toLowerCase(), language: langCode ? langCode : 'en' };
            const res = await forgot(payload).unwrap();
            if (res) {
                setActiveScreen('verify')
                Toast("s", res?.message);
            }
        } catch (error) {
            Toast("e", error?.data?.message ?? error?.status);
        }
    };

    // const handleVerifyOtp = async () => {
    //     try {
    //         const payload = { email: email, otp: otpVal };
    //         const res = await verify(payload).unwrap();
    //         if (res) {
    //             setActiveScreen('reset_password')
    //             Toast("s", res?.message);
    //         }
    //     } catch (error) {
    //         Toast("e", error?.data?.message ?? error?.status);
    //     }
    // };

    const handleResetPassword = async (formVal) => {
        if (formVal?.new_password?.length < 4) {
            return Toast('e', 'New password length must be at least 4 characters long');
        }
        try {
            const payload = { email: email, new_password: formVal?.new_password, language: langCode ? langCode : 'en' };
            const res = await reset(payload).unwrap();
            if (res) {
                Toast("s", res?.message);
                navigate('/login');
            }
        } catch (error) {
            Toast("e", error?.data?.message ?? error?.status);
        }
    };


    return (
        <>
            {activeScreen === 'forgot' &&
                <div className="extra_margin">
                    <div className="d-flex align-items-start justify-content-between">
                        <h1 className="big_font text-white mb-4">{t("auth.forgot_password")}</h1>
                        <img src={Assets.logoBall} alt="" width='60px' className="d-md-none d-lg-none d-block" />
                    </div>
                    <Form onFinish={handleForgot} layout="vertical">
                        <Form.Item className="my-5 my-md-4 my-lg-4" name='email' label={<dt className="small_font">{t("auth.email")}:</dt>}>
                            <CustomInput type="email" required={true} />
                        </Form.Item>
                        <Form.Item>
                            <div className="btn_border">
                                <CustomButton
                                    htmlType='submit'
                                    className={`w-100`}
                                    style={{ background: `var(--green)`, color: `var(--black)` }}
                                    loading={isLoading}
                                >
                                    {t("auth.send_email")}
                                </CustomButton>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            }

            {activeScreen === 'verify' &&
                // <div className="row">
                //     <div className="col-xl-10 col-xxl-12 mx-auto">
                //         <div className="extra_margin">
                //             <div className="d-flex align-items-start justify-content-between">
                //                 <h2 className="big_font text-white mb-4">{t("auth.email_verification")}</h2>
                //                 <img src={Assets.logoBall} alt="" width='60px' className="d-md-none d-lg-none d-block" />
                //             </div>
                //             <div className="px-1">
                //                 <dt className='small_font text-white mb-2'>Enter OTP:</dt>
                //                 <VerificationInput
                //                     placeholder="*"
                //                     length={4}
                //                     value={otpVal}
                //                     onChange={(val) => setOtpVal(val)}
                //                 />
                //                 <div className="d-flex align-items-center justify-content-between mt-5">
                //                     <b className="so_small_font text-white">Didn&apos;t receive the code?</b>
                //                     <b role="button" tabIndex={0} className="so_small_font text-white" style={{ cursor: "pointer" }}>{t("buttons.resend")}</b>
                //                 </div>
                //                 <div className="btn_border my-4 my-lg-5">
                //                     <CustomButton
                //                         htmlType='submit'
                //                         className={` w-100`}
                //                         style={{ background: `var(--green)`, color: `var(--black)` }}
                //                         loading={verifyLoader}
                //                         onClick={() => handleVerifyOtp()}
                //                     >
                //                         {t("buttons.continue")}
                //                     </CustomButton>
                //                 </div>
                //             </div>
                //         </div>
                //     </div>
                // </div>
                <VerifyScreen email={email} setActiveScreen={setActiveScreen} />
            }

            {activeScreen === 'reset_password' &&
                <div className="extra_margin">
                    <div className="d-flex align-items-start justify-content-between">
                        <h1 className="big_font text-white mb-4">{t("auth.reset_password")}</h1>
                        <img src={Assets.logoBall} alt="" width='60px' className="d-md-none d-lg-none d-block" />
                    </div>
                    <Form onFinish={handleResetPassword} layout="vertical">
                        <Form.Item name='new_password' label={<dt className="small_font">{t("auth.new_password")}:</dt>}>
                            <PasswordInput
                                required={true}
                                className="common_input"
                            />
                        </Form.Item>
                        <Form.Item
                            name='c_password'
                            label={<dt className="small_font">{t("auth.confirm_password")}:</dt>}
                            dependencies={['new_password']}
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('new_password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Both password does not match!'));
                                    },
                                }),
                            ]}
                        >
                            <PasswordInput
                                required={true}
                                className="common_input"
                            />
                        </Form.Item>
                        <Form.Item>
                            <div className="btn_border my-5">
                                <CustomButton
                                    htmlType='submit'
                                    className={`w-100`}
                                    style={{ background: `var(--green)`, color: `var(--black)` }}
                                    loading={resetLoader}
                                >
                                    {t("buttons.continue")}
                                </CustomButton>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            }
        </>
    )
}

export default Forgot;
