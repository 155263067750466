import { Form } from "antd";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import { useAddCardMutation, useDeleteCardMutation } from "../../../redux/features/payment/paymentApi";
import { useLocation } from "react-router-dom";
import CustomInput from "components/CustomInput";
import CustomButton from "components/CustomButton";
import Toast from "utils/Toast";

const PaymentForm = ({ setCardModal, cardId }) => {
    const [paymentForm] = Form.useForm();
    const location = useLocation();
    const { t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();
    const [addCard, { isLoading }] = useAddCardMutation();
    const [deleteCard, { isLoading: delLoader }] = useDeleteCardMutation();

    const handleAddCard = async () => {
        if (!stripe || !elements) {
            return;
        }
        const cardElement = elements.getElement(CardNumberElement);
        const { error, token } = await stripe.createToken(cardElement);

        if (error) {
            Toast('e', error.message);
            return;
        }

        try {
            const res = await addCard({ token: token.id }).unwrap();
            if (res) {
                paymentForm.resetFields();
                setCardModal(false);
            }
        } catch (err) {
            Toast('e', err.data?.message ?? err.message);
        }
    };

    const handleDeleteCard = async () => {
        try {
            const res = await deleteCard({ card_id: cardId }).unwrap();
            if (res) {
                Toast('s', res?.message);
                setCardModal(false);
            }
        } catch (error) {
            Toast("e", error?.data?.message ?? error?.status);
        }
    };

    return (
        <>
            <Form form={paymentForm} onFinish={handleAddCard} layout="vertical">
                <Form.Item name='holder_name' label={<dt style={{ color: `var(--text-gray)` }} className="so_small_font">Card holder name</dt>}>
                    <CustomInput required className="payment_input text-black fw-normal" />
                </Form.Item>
                <Form.Item name='card_number' label={<dt style={{ color: `var(--text-gray)` }} className="so_small_font">Card number</dt>}>
                    <CardNumberElement options={{ showIcon: true }} className="payment_input" />
                </Form.Item>
                <div className="row">
                    <div className="col-md-6 col-lg-6">
                        <Form.Item name='exp_card' label={<dt style={{ color: `var(--text-gray)` }} className="so_small_font">Expiry date</dt>}>
                            <CardExpiryElement options={{ showIcon: true }} className="payment_input" />
                        </Form.Item>
                    </div>
                    <div className="col-md-6 col-lg-6">
                        <Form.Item name='cvv_card' label={<dt style={{ color: `var(--text-gray)` }} className="so_small_font">CCV/CVC</dt>}>
                            <CardCvcElement options={{ showIcon: true }} className="payment_input" />
                        </Form.Item>
                    </div>
                </div>
                <Form.Item>
                    <div className="d-flex justify-content-center gap-3 mt-2">
                        <div className="btn_border border-black w-50">
                            <CustomButton
                                style={{ background: `var(--green)`, color: `var(--black)`, width: '100%' }}
                                htmlType='submit'
                                loading={isLoading}
                            >
                                {t("buttons.save")}
                            </CustomButton>
                        </div>
                        {location.pathname === '/settings' &&
                            <div className="btn_border border-black w-50">
                                <CustomButton
                                    style={{ background: `var(--pink-bad)`, color: `var(--black)`, width: '100%' }}
                                    onClick={handleDeleteCard}
                                    loading={delLoader}
                                >
                                    <span className="">Delete this card</span>
                                </CustomButton>
                            </div>
                        }
                    </div>
                </Form.Item>
            </Form>
        </>
    )
}

export default PaymentForm;
