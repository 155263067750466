import { useEffect, useState } from "react";
import { Icons } from "utils/Icons";
import { useNavigate } from "react-router-dom";
import { useUniversalListQuery } from "../../redux/features/universal/universalApi";
import { file_url } from "constant/url";
import { useDispatch } from "react-redux";
import { universalData } from "../../redux/features/universal/universalSlice";
import { handleEnterKeyDown } from "utils/Constant";
import { Helmet } from "react-helmet";
import CustomInput from "components/CustomInput";

const Universals = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState([]);
  const { data } = useUniversalListQuery();

  useEffect(() => {
    setSearchData(data?.data);
  }, [data]);

  const searchResult = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filteredData = data?.data?.filter(item =>
      item?.universal_name?.toLowerCase().includes(searchTerm) ||
      item?.universal_description?.toLowerCase().includes(searchTerm)
    );
    setSearchData(filteredData);
  };

  return (
    <>
      <Helmet>
        <title>Universals - Premier Futbol Institute</title>
        <meta name="description" content="Explore and manage universals effectively for your training and planning needs." />
        <meta property="og:title" content="Manage Universals - Premier Futbol Institute" />
        <meta property="og:description" content="Explore and manage universals effectively for your training and planning needs." />
        <meta property="og:url" content="https://dev.d2amudylbskc3c.amplifyapp.com/universals" />
        <meta property="og:type" content="website" />
      </Helmet>

      <CustomInput
        onChange={searchResult}
        placeholder="Search"
        className="border-black text-dark"
        suffix={<Icons.IoMdSearch size={22} />}
      />

      <div className="row g-3 mt-1 mt-xl-4">
        {searchData?.map((item) => (
          <div className="col-lg-6" key={item?._id}>
            <div
              role="button"
              tabIndex={0}
              onKeyDown={() => handleEnterKeyDown()}
              onClick={() => {
                navigate(`/universal-detail/${item?._id}`);
                dispatch(universalData(item?.universal_name));
              }}
              className="common_card align-items-center h-100"
            >
              <div className="d-flex align-items-center gap-2 gap-lg-3">
                <div
                  style={{
                    background: `url(${file_url}${item?.universal_icon}) center / cover no-repeat`,
                    width: '60px',
                    height: '60px',
                    borderRadius: '20px',
                  }}
                />
                <div className="text_content">
                  <h1 className="medium_font fw-bold mb-1">{item?.universal_name}</h1>
                  <dt className="so_small_font" style={{ color: 'var(--text-gray)' }}>
                    {item?.universal_description}
                  </dt>
                </div>
              </div>
              <Icons.IoIosArrowForward size={25} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Universals;
