import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { base_url } from 'constant/url';
import Toast from 'utils/Toast';

const baseQueryWithErrorHandling = async (args, api, extraOptions) => {
    const rawBaseQuery = fetchBaseQuery({
        baseUrl: base_url,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('token');
            const newTok = JSON.parse(token);

            if (token) {
                headers.set('Authorization', `Bearer ${newTok?.accessToken}`);
            }
            return headers;
        },
    });

    try {
        const result = await rawBaseQuery(args, api, extraOptions);

        if (result.error) {
            const { status } = result.error;

            if (status === 401 || status === 403) {
                localStorage.clear();
                window.location.href = "/";
            }
            return { error: result.error };
        }
        return result;
    } catch (error) {
        Toast("e", 'An unexpected error occurred.');
        return { error: 'An unexpected error occurred.' };
    }
};

export const baseApi = createApi({
    reducerPath: 'baseApi',
    baseQuery: baseQueryWithErrorHandling,
    keepUnusedDataFor: 60, 
    endpoints: () => ({
        
    }),
});
