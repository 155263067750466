import { baseApi } from "../../app/baseApi";
import { endpoints } from "utils/Endpoint";

const settingApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        userDetail: builder.query({
            providesTags: ['getUser'],
            query: () => ({
                url: endpoints?.get_user_details,
                method: 'GET',
            }),
        }),
        updateUserDetail: builder.mutation({
            query: (data) => ({
                url: endpoints?.update_user_details,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ['getUser']
        }),
        changePassword: builder.mutation({
            query: (data) => ({
                url: endpoints?.change_password,
                method: 'POST',
                body: data
            }),
        }),
        contact: builder.mutation({
            query: (data) => ({
                url: endpoints?.contact_us,
                method: 'POST',
                body: data
            }),
        }),
    }),
});

export const { useUserDetailQuery, useUpdateUserDetailMutation, useChangePasswordMutation, useContactMutation } = settingApi;