import { useEffect, useState } from "react";
import { Modal, Progress } from "antd";
import { Icons } from "utils/Icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Assets } from "utils/Assets";
import { Trans, useTranslation } from "react-i18next";
import { useCycleStepsQuery, useListSessionQuery, useResetSessionMutation, useSessionProgressMutation, useUpdateSessionMutation } from "../../redux/features/myplan/planApi";
import { useDispatch } from "react-redux";
import { cycleNumber, cycleStepsData, sessionDetail } from "../../redux/features/myplan/planSlice";
import CustomButton from "components/CustomButton";
import useWindowHeight from "utils/useWindowHeight";
import Toast from "utils/Toast";

const Session = () => {
    const windowHeight = useWindowHeight();
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [congratsModal, setCongratsModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [searchParam, setSearchParam] = useSearchParams(1);
    const [quantity, setQuantity] = useState(0);
    const [progressData, setProgressData] = useState('');
    const { data } = useListSessionQuery(id);
    const [updateSession] = useUpdateSessionMutation();
    const [sessionProgress] = useSessionProgressMutation();
    const [resetSession, { isLoading }] = useResetSessionMutation();
    const { data: cycleSteps } = useCycleStepsQuery(id);
    const filterCycle = data?.data?.filter(item => item?.session_cycle === quantity);
    const sessionStatus = data?.data?.every(item => item?.session_status === 2);
    // const singleCycle = filterCycle?.every(item => item?.session_status === 2);


    const getProgress = async () => {
        try {
            const res = await sessionProgress({ team_id: id, cycle_no: quantity?.toString() }).unwrap();
            setProgressData(res?.data)
        } catch (error) {
            Toast("e", error?.data?.message ?? error?.status);
        }
    };

    const handleStartSession = async (item) => {
        dispatch(sessionDetail(item));
        navigate(`/drills/${item?._id}`);
        try {
            if (item?.session_status === 0) {
                await updateSession({ session_id: item?._id }).unwrap();
            } else {
                navigate(`/drills/${item?._id}`)
            }
        } catch (error) {
            Toast("e", error?.data?.message ?? error?.status);
        }
    };

    const handleResetSession = async () => {
        try {
            const res = await resetSession().unwrap();
            if (res) {
                Toast("s", res?.message);
                setDeleteModal(false);
                localStorage.removeItem('activeTab')
            }
        } catch (error) {
            Toast("e", error?.data?.message ?? error?.status);
        }
    };

    const decrease = () => {
        setSearchParam({ cycle_no: quantity - 1 })
    };

    const increase = () => {
        // if (!singleCycle) {
        //     return Toast('e', `Your cycle number  ${quantity} sessions not completed yet!`)
        // }
        setSearchParam({ cycle_no: quantity + 1 })
    };

    useEffect(() => {
        const cycleNo = Number(searchParam.get("cycle_no"));
        if (cycleNo) {
            setQuantity(cycleNo);
            dispatch(cycleNumber(cycleNo))
        } else {
            setQuantity(1)
        }
    }, [searchParam]);

    useEffect(() => {
        dispatch(cycleStepsData({ cycle: cycleSteps?.data, team_id: id }));
    }, [cycleSteps]);

    useEffect(() => {
        getProgress();
    }, [quantity]);

    return (
        <>
            <div className="mb-4 d-sm-none d-md-none d-lg-none"><img src={Assets.mobileStepImg} alt="" className="w-100" /></div>
            <div className="content_height">
                <div className="row g-3 g-xl-4">
                    {filterCycle?.map((item) => {
                        return (
                            <div className="col-12 col-lg-6" key={item?._id}>
                                <div className="common_card" role="button" tabIndex={0} onKeyDown={() => ''} onClick={() => handleStartSession(item)}>
                                    <div className="d-flex align-items-center gap-2 gap-xl-3">
                                        {item?.session_status === 2 ? <Icons.FaRegCheckCircle size={25} /> : <Icons.FaRegCircle size={25} color={`var(--btn-gray)`} />}
                                        {/* {item?.session_status === 2 ? <Icons.FaRegCheckCircle size={25} /> : item?.session_status === 1 ? <Icons.FaRegCircle size={25} /> : <Icons.FaRegCircle size={25} color={`var(--btn-gray)`} />} */}
                                        <div>
                                            <h1 className="medium_font mb-0">{t("header.session")} {item?.session_no}</h1>
                                            <p className="small_font fw-normal text-dark mb-0">{item?.universal_name}</p>
                                            <span className="so_small_font" style={{ color: 'var(--text-gray)' }}>
                                                {item?.session_status === 2 ? `${t("my_plan.session.completed")}` : `${t("my_plan.session.not_started")}`}
                                                {/* {item?.session_status === 2 ? `${t("my_plan.session.completed")}` : item?.session_status === 1 ? `${t("my_plan.session.in_progress")}` : `${t("my_plan.session.not_started")}`} */}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap-1 gap-lg-2 gap-xl-3">
                                        {item?.session_performance &&
                                            <span className="route_icon small_font px-3 upper_case" style={{ background: item?.session_performance === `${t("buttons.good").toLowerCase()}` ? `var(--blue-good)` : item?.session_performance === `${t("buttons.average").toLowerCase()}` ? `var(--yellow-normal)` : item?.session_performance === `${t("buttons.poor").toLowerCase()}` ? `var(--pink-bad)` : "" }}>
                                                {item?.session_performance}
                                            </span>
                                        }
                                        <Icons.IoIosArrowForward size={25} />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {data?.data?.length > 0 &&
                <div className={windowHeight < 750 ? 'mt-lg-4' : "fixed_footer"}>
                    <div className="common_card">
                        <div className="row g-3 w-100">
                            <h6 className="small_font fw-normal text-black">{t("my_plan.session.progress_text")}</h6>
                            <div className="col-lg-4">
                                <div className="d-flex">
                                    {progressData?.good > 0 ? (
                                        <>
                                            <Progress percent={progressData?.good} strokeColor={`var(--blue-good)`} size={['100%', 20]}
                                                percentPosition={{
                                                    align: 'start',
                                                    type: 'inner',
                                                }}
                                                format={() => progressData?.good > 0 && <dt className="text-black px-2"> {t("buttons.good")}</dt>}
                                            />
                                            <dt style={{ marginLeft: '10px' }}>{progressData?.good?.toFixed(0)}%</dt>
                                        </>
                                    ) : (
                                        <Progress percent={progressData?.good} strokeColor={`var(--blue-good)`} size={['100%', 20]} />
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="d-flex">
                                    {progressData?.average > 0 ? (
                                        <>
                                            <Progress percent={progressData?.average} strokeColor={`var(--yellow-normal)`} size={['100%', 20]}
                                                percentPosition={{
                                                    align: 'start',
                                                    type: 'inner',
                                                }}
                                                format={() => progressData?.average > 0 && <dt className="text-black px-2"> {t("buttons.average")}</dt>}
                                            />
                                            <dt style={{ marginLeft: '10px' }}>{progressData?.average?.toFixed(0)}%</dt>
                                        </>
                                    ) : (
                                        <Progress percent={progressData?.average} strokeColor={`var(--yellow-normal)`} size={['100%', 20]} />
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="d-flex">
                                    {progressData?.poor > 0 ? (
                                        <>
                                            <Progress percent={progressData?.poor} strokeColor={`var(--pink-bad)`} size={['100%', 20]}
                                                percentPosition={{
                                                    align: 'start',
                                                    type: 'inner',
                                                }}
                                                format={() => progressData?.poor > 0 && <dt className="text-black px-2"> {t("buttons.poor")}</dt>}
                                            />
                                            <dt style={{ marginLeft: '10px' }}>{progressData?.poor?.toFixed(0)}%</dt>
                                        </>
                                    ) : (
                                        <Progress percent={progressData?.poor} strokeColor={`var(--pink-bad)`} size={['100%', 20]} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex align-items-center gap-2 flex-wrap justify-content-between">
                        {quantity > 1 ?
                            <CustomButton disabled={quantity <= 1} onClick={decrease} iconPosition="start" icon={<Icons.IoIosArrowBack color={`var(--black)`} size={25} />} className="common_btn border border-2 border-black text-end ps-1 pe-3 w-auto">
                                <dt className="so_small_font text-black">{quantity > 1 && <span>{t("buttons.back")}:<br /></span>} <span className="small_font text-black">{t("header.cycle")} {quantity - 1}</span></dt>
                            </CustomButton>
                            :
                            <CustomButton iconPosition="start" icon={<Icons.IoIosArrowBack color={`transparent`} size={25} />} className="common_btn border border-2 border-light text-end ps-1 pe-3 w-auto">
                                <dt className="small_font text-light">Cycle -</dt>
                            </CustomButton>
                        }
                        {quantity >= 3 &&
                            <div className="btn_border border-black d-none d-sm-block d-md-block d-lg-block">
                                <CustomButton className="common_btn text-black px-4 px-lg-5 w-auto" style={{ background: `var(--green)` }} onClick={() => setCongratsModal(true)}>
                                    {t("my_plan.session.training_plan")}
                                </CustomButton>
                            </div>}
                        {quantity < 3 ?
                            <CustomButton disabled={quantity >= 3} onClick={increase} iconPosition="end" icon={<Icons.IoIosArrowForward color={`var(--black)`} size={25} />} className={"common_btn border border-2 border-black text-start ps-3 pe-1 w-auto"}>
                                <dt className="so_small_font text-black">{t("buttons.go")}:<br /> <span className="small_font text-black"> {t("header.cycle")} {quantity + 1}</span></dt>
                            </CustomButton>
                            :
                            <CustomButton iconPosition="end" icon={<Icons.IoIosArrowForward color={`transparent`} size={25} />} className="common_btn border border-2 border-light text-start ps-3 pe-1 w-auto">
                                <dt className="small_font text-light">Cycle -</dt>
                            </CustomButton>
                        }
                        {quantity >= 3 &&
                            <div className="btn_border border-black d-block d-sm-none d-md-none d-lg-none w-100 mt-2 mt-md-4 mt-lg-4">
                                <CustomButton className="common_btn text-black w-100" style={{ background: `var(--green)` }} onClick={() => setCongratsModal(true)}>
                                    {t("my_plan.session.training_plan")}
                                </CustomButton>
                            </div>}
                    </div>
                </div>
            }

            {/* congrats modal */}
            <Modal title={<img src={Assets.darkLogo} alt="" width={180} />} open={congratsModal} footer='' centered width={600} closeIcon={<img src={Assets.closeIcon} alt="" />} onCancel={() => setCongratsModal(false)}>
                {!sessionStatus ?
                    <div className="text-center">
                        <h2 className="big_font">&quot;You still have incomplete sessions.&quot;</h2>
                        <h6 className="medium_font fw-normal">Please complete your session first...</h6>
                    </div>
                    :
                    <>
                        <img src={Assets.trophyImg} alt="" width='20%' />
                        <h4 className="medium_font my-4">{t("my_plan.session.congrats")}</h4>
                        <div className="row g-4">
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="btn_border border-black">
                                    <CustomButton
                                        onClick={() => setCongratsModal(false)}
                                        style={{ background: `var(--green)`, color: `var(--black)`, width: '100%' }}
                                    >
                                        {t("buttons.leave_plan")}
                                    </CustomButton>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="btn_border border-black">
                                    <CustomButton
                                        onClick={() => { setDeleteModal(true); setCongratsModal(false) }}
                                        style={{ background: `var(--pink-bad)`, color: `var(--black)`, width: '100%' }}
                                    >
                                        <Trans i18nKey="buttons.delete_plan" components={[{ 1: <br /> }]} />
                                    </CustomButton>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Modal>

            {/* Session Incomplete Modal */}

            {/* delete modal */}
            <Modal open={deleteModal} footer='' centered width={600} closeIcon={<img src={Assets.closeIcon} alt="" />} onCancel={() => setDeleteModal(false)}>
                <img src="" alt="" />
                <h4 className="medium_font">{t("my_plan.session.delete_plan")}</h4>
                <h4 className="medium_font my-4">{t("my_plan.session.lost_plan")}</h4>
                <div className="d-flex gap-4 pt-3">
                    <div className="btn_border border-black w-50">
                        <CustomButton
                            style={{ background: `var(--green)`, color: `var(--black)`, width: '100%' }}
                            onClick={handleResetSession}
                            loading={isLoading}
                        >
                            {t("buttons.yes")}
                        </CustomButton>
                    </div>
                    <div className="btn_border border-black w-50">
                        <CustomButton
                            onClick={() => setDeleteModal(false)}
                            style={{ background: `var(--pink-bad)`, color: `var(--black)`, width: '100%' }}
                        >
                            {t("buttons.no")}
                        </CustomButton>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Session;
