import { BiLogOutCircle } from "react-icons/bi";
import { IoMdArrowDropdown, IoIosArrowForward, IoIosArrowBack, IoMdSearch, IoMdAdd, IoIosArrowDown } from "react-icons/io";
import { IoSettingsSharp } from "react-icons/io5";
import { FaEye, FaEyeSlash, FaApple, FaFacebookF, FaCalendarAlt, FaEdit, FaRegCircle, FaRegCheckCircle } from "react-icons/fa";
import { FaEarthAmericas, FaMinus } from "react-icons/fa6";
import { FcGoogle } from "react-icons/fc";


export const Icons = {
    BiLogOutCircle: ({ style, color, size }) => <BiLogOutCircle color={color} size={size} style={{ ...style }} />,
    IoMdArrowDropdown: ({ style, color, size }) => <IoMdArrowDropdown color={color} size={size} style={{ ...style }} />,
    IoIosArrowForward: ({ style, color, size, onClick }) => <IoIosArrowForward color={color} size={size} style={{ ...style }} onClick={onClick} />,
    IoIosArrowBack: ({ style, color, size, onClick }) => <IoIosArrowBack color={color} size={size} style={{ ...style }} onClick={onClick} />,
    IoMdSearch: ({ style, color, size }) => <IoMdSearch color={color} size={size} style={{ ...style }} />,
    IoSettingsSharp: ({ style, color, size }) => <IoSettingsSharp color={color} size={size} style={{ ...style }} />,
    IoIosArrowDown: ({ style, color, size }) => <IoIosArrowDown color={color} size={size} style={{ ...style }} />,
    IoMdAdd: ({ style, color, size }) => <IoMdAdd color={color} size={size} style={{ ...style }} />,
    FaEye: ({ style, color, size }) => <FaEye color={color} size={size} style={{ ...style }} />,
    FaEyeSlash: ({ style, color, size }) => <FaEyeSlash color={color} size={size} style={{ ...style }} />,
    FaApple: ({ style, color, size }) => <FaApple color={color} size={size} style={{ ...style }} />,
    FaFacebookF: ({ style, color, size }) => <FaFacebookF color={color} size={size} style={{ ...style }} />,
    FaEarthAmericas: ({ style, color, size }) => <FaEarthAmericas color={color} size={size} style={{ ...style }} />,
    FaCalendarAlt: ({ style, color, size }) => <FaCalendarAlt color={color} size={size} style={{ ...style }} />,
    FaEdit: ({ style, color, size }) => <FaEdit color={color} size={size} style={{ ...style }} />,
    FaRegCircle: ({ style, color, size }) => <FaRegCircle color={color} size={size} style={{ ...style }} />,
    FaRegCheckCircle: ({ style, color, size }) => <FaRegCheckCircle color={color} size={size} style={{ ...style }} />,
    FcGoogle: ({ style, color, size }) => <FcGoogle color={color} size={size} style={{ ...style }} />,
    FaMinus: ({ style, color, size }) => <FaMinus color={color} size={size} style={{ ...style }} />,
}