import SelectPayment from "pages/selectpayment/SelectPayment";

const PaymentMethod = () => {
    return (
        <>
            {/* <PaymentCard activeCard={activeCard} setActiveCard={setActiveCard} listCard={listCard} /> */}
            <SelectPayment/>
        </>
    )
}

export default PaymentMethod;
