import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateUserDetailMutation } from "../../redux/features/settings/settingApi";
import { handleEnterKeyDown } from "utils/Constant";
import { languageList } from "utils/itemsLIst";
import Toast from "utils/Toast";

const ChangeLanguage = () => {
    const { i18n } = useTranslation();
    const [activeLanguage, setActiveLanguage] = useState(i18n.language);
    const [updateUser] = useUpdateUserDetailMutation();

    const handleChangeLanguage = async (lng) => {
        i18n.changeLanguage(lng);
        setActiveLanguage(lng);
        try {
            const payload = { language: lng }
            const res = await updateUser(payload).unwrap();
            if (res) {
                window.location.reload();
            }
        } catch (error) {
            Toast("e", error?.data?.message ?? error?.status);
        }
    };

    return (
        <>
            {languageList?.map((item) => (
                <div key={item?.value} role="button" tabIndex={0} onKeyDown={handleEnterKeyDown(item?.value, setActiveLanguage)} onClick={() => handleChangeLanguage(item?.value)}>
                    <div className="d-flex align-items-center gap-3">
                        <div className="radio">
                            <div className={activeLanguage === item?.value ? `mini_radio` : 'mini_radio bg-white'} />
                        </div>
                        <dt className="fw-bold fs-6">{item?.label}</dt>
                    </div>
                    <hr />
                </div>
            ))}
        </>
    )
}

export default ChangeLanguage;
