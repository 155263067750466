import { useRef, useState } from "react";
import { Modal } from "antd";
import { file_url } from "constant/url";
import { Assets } from "utils/Assets";
import CustomButton from "./CustomButton";

const VideoModal = ({ videoModal, setVideoModal, videosrc }) => {
    const videoRef = useRef();
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayVideo = () => {
        const video = videoRef.current;
        if (video.paused) {
            video.play();
            setIsPlaying(true);
        } else {
            video.pause();
            setIsPlaying(false);
        }
    }

    const handleVideoPause = () => {
        setIsPlaying(false);
    };

    const handleCancleModal = () => {
        setVideoModal(false);
        setIsPlaying(false);
        videoRef.current.pause();
    }

    return (
        <>
            <Modal title={<img src={Assets.darkLogo} alt="" width={150} />} open={videoModal} onCancel={handleCancleModal} footer='' closeIcon={<img src={Assets.closeIcon} alt="" />} width={600}>
                <div className="video_position w-100">
                    <video
                        ref={videoRef}
                        controls={isPlaying}
                        src={`${file_url}${videosrc}`}
                        onPause={handleVideoPause}
                        className="video_style w-100 h-100 mt-2"
                    >
                        <track kind="captions" />
                    </video>
                    {!isPlaying && (
                        <CustomButton className="p-0 w-auto play_btn_position" onClick={handlePlayVideo}>
                            <img src={Assets.playBtn} alt="" width='50px' />
                        </CustomButton>
                    )}
                </div>
            </Modal>
        </>
    )
}

export default VideoModal;
