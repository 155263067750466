import { useEffect, useState } from "react";
import { Modal } from "antd";
import { Assets } from "utils/Assets";
import { useTranslation } from "react-i18next";
import { useListTeamQuery } from "../../redux/features/myplan/planApi";
import { commonData, userData } from "../../redux/features/common/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCancelSubscriptionMutation, useListSubscriptionQuery } from "../../redux/features/payment/paymentApi";
import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import Toast from "utils/Toast";
import moment from "moment";

const ManageSubscription = () => {
    const userDetail = useSelector(state => state?.common?.user_data);
    const [quantity, setQuantity] = useState(userDetail?.no_of_teams ?? 1);
    const [confirmModal, setConfirmModal] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { data } = useListTeamQuery();
    const { data: listSubscription } = useListSubscriptionQuery();
    const [cancelSubs, { isLoading }] = useCancelSubscriptionMutation();
    const subsObj = listSubscription?.data?.find(item => item?.name === quantity?.toString());
    const totalAmount = subsObj?.price;


    const increase = () => {
        if (quantity < listSubscription?.data?.length) {
            setQuantity(quantity + 1);
        }
    };

    const decrease = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const handleCancelSubscription = async () => {
        try {
            const res = await cancelSubs({ subscription_id: userDetail?.subscription_object?.subscription_id }).unwrap();
            // console.log(res, 'success')
            if (res) {
                setConfirmModal(false);
                Toast("s", res?.message);
                dispatch(userData(res?.data));
            }

        } catch (error) {
            Toast("e", error?.data?.message ?? error?.status);
        }
    };

    useEffect(() => {
        dispatch(commonData({ quantity: subsObj?.name, amount: totalAmount, subscriptionData: subsObj }))
    }, [quantity, totalAmount]);

    return (
        <>
            <div className="d-flex flex-column justify-content-between gap-lg-5 h-100">
                <div>
                    <h1 className="medium_font mb-3">{t("settings.manage_subs.current_used_plans")}:</h1>
                    {data?.data?.map((item) => {
                        return (
                            <div className="common_card gap-3 p-2 pe-3 mb-3" key={item?._id}>
                                <div className="common_card w-100 py-2" style={{ background: `var(--light-gray)` }}>
                                    <div>
                                        <dt className="medium_font">{item?.plan_id?.plan_name}</dt>
                                        <dt className="small_font" style={{ color: 'var(--text-gray)' }}>{item?.plan_id?.plan_description}</dt>
                                    </div>
                                    <div>
                                        <dt className="medium_font">{item?.team_name}</dt>
                                        <dt className="small_font text-end" style={{ color: 'var(--text-gray)' }}>Used</dt>
                                    </div>
                                </div>
                                <CustomButton type="ghost" className="route_icon h-auto w-auto" style={{ background: `var(--light-gray)` }}><img src={Assets.deleteIcon} alt="" width='18px' /></CustomButton>
                            </div>
                        )
                    })}
                </div>

                <div>
                    <h2 className="medium_font">{t("settings.manage_subs.change_your_plan")}:</h2>
                    <div className="common_card">
                        <div className="w-100">
                            <h5 className="small_font text-dark">{subsObj?.name} {t("teams.team_cost")} <span className="medium_font">${subsObj?.price}</span>/{t("teams.month")}</h5>
                            <div className="my-3 my-md-5 my-lg-5">
                                <dt className="small_font mb-2" style={{ color: `var(--text-gray)` }}>{t("teams.select_teams_info")}</dt>
                                <div className="btn_border d-flex align-items-center border-black">
                                    <CustomButton
                                        onClick={decrease}
                                        disabled={quantity <= 1}
                                        style={{ background: `var(--blue)`, padding: '0 1.3rem', width: 'auto' }}
                                    >
                                        <dt className="small_font text-white fs-4">-</dt>
                                    </CustomButton>
                                    <CustomInput
                                        type="number"
                                        value={quantity}
                                        readOnly
                                        className="border-0 text-center fs-4 text-black"
                                    />
                                    <CustomButton
                                        onClick={increase}
                                        disabled={quantity >= listSubscription?.data?.length}
                                        style={{ background: `var(--blue)`, padding: '0 1.3rem', width: 'auto' }}
                                    >
                                        <dt className="small_font text-white">+</dt>
                                    </CustomButton>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between text-end">
                                <h4 className="small_font text-dark">{t("teams.total")}:</h4>
                                <h4 className="small_font text-dark"> {subsObj?.name} {t("teams.teams")} <br /> ${totalAmount}/{t("teams.month")}</h4>
                            </div>
                            <div className="row mt-lg-4">
                                <div className="col-md-4 col-lg-4 ms-auto">
                                    <div className="btn_border border-black">
                                        <CustomButton
                                            style={{ background: `var(--green)`, color: `var(--black)` }}
                                            className="w-100"
                                            onClick={() => navigate(`/settings?tab=${4}`)}
                                        >
                                            {t("buttons.next")}
                                        </CustomButton>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center my-3">
                        {userDetail?.subscription_object?.subscription_status === "active" ?
                            <h6 className="small_font text-black">Next Billing date: {moment(userDetail?.subscription_object?.end_date * 1000).add(1, 'days').format('ll')}</h6>
                            :
                            ""
                        }
                        {userDetail?.subscription_object?.subscription_status === "" ? "" :
                            <CustomButton
                                style={{ background: `var(--btn-gray)` }}
                                className="w-auto py-2 px-3 text-black"
                                onClick={() => setConfirmModal(true)}
                            >
                                {userDetail?.subscription_object?.subscription_status === "active" ? "Cancel Subscription" : "Cancelled"}
                            </CustomButton>
                        }
                    </div>
                </div>
            </div>

            {/* subscription cancelled modal */}
            <Modal title={<img src={Assets.darkLogo} alt="" width={180} />} open={confirmModal} footer='' centered closeIcon={<img src={Assets.closeIcon} alt="" />} onCancel={() => setConfirmModal(false)}>
                {userDetail?.subscription_object?.subscription_status === "active" ?
                    <div className="mt-4">
                        <h3 className="text-center">Are you sure you want to cancel this subscription ?</h3>
                        <div className="d-flex gap-4 mt-4">
                            <div className="btn_border border-black w-50">
                                <CustomButton
                                    onClick={() => handleCancelSubscription()}
                                    style={{ background: `var(--green)`, color: `var(--black)`, width: '100%' }}
                                    loading={isLoading}
                                >
                                    {t("buttons.yes")}
                                </CustomButton>
                            </div>
                            <div className="btn_border border-black w-50">
                                <CustomButton
                                    onClick={() => setConfirmModal(false)}
                                    style={{ background: `var(--btn-gray)`, color: `var(--black)`, width: '100%' }}
                                >
                                    {t("buttons.cancel")}
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="mt-4">
                        <h3 className="text-center">Your Subscription Already Cancelled On {moment(userDetail?.subscription_object?.cancelled_on * 1000).format('ll')}</h3>
                    </div>
                }
            </Modal>
        </>
    )
}

export default ManageSubscription;
