import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBw9lhH1WqGsavnjXUXc7Xdg03sKx3T8BQ",
  authDomain: "premier-futbol-institute.firebaseapp.com",
  projectId: "premier-futbol-institute",
  storageBucket: "premier-futbol-institute.appspot.com",
  messagingSenderId: "798865432051",
  appId: "1:798865432051:web:60a898dede1d790df10686",
  measurementId: "G-ESYYQ4RWYW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const socialAuth = getAuth(app);
