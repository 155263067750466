import { useEffect } from "react";
import { Select } from "antd";
import { Assets } from "utils/Assets";
import { Icons } from "utils/Icons";
import { useNavigate } from "react-router-dom";
import { languageList } from "utils/itemsLIst";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { languageCode } from "../../redux/features/common/commonSlice";
import countryToCurrency from 'country-to-currency';
import CustomButton from "components/CustomButton";
import Toast from "utils/Toast";

const LanguageSelector = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();

    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang)
        dispatch(languageCode(lang))
    }

    // Function to get user's current location
    function getCurrentLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    await getCountryFromCoordinates(latitude, longitude);
                },
                (error) => {
                    localStorage.setItem('currency_data', "USD")
                    localStorage.setItem("country", "Usa")
                }
            );
        } else {
            Toast('e','Geolocation is not supported by this browser.');
        }
    }

    async function getCountryFromCoordinates(latitude, longitude) {
        try {
            const response = await fetch(`https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=6525640448c8444d9d2a1c62108a2a18`);
            if (!response.ok) {
                Toast('e', 'Failed to fetch data');
            }
            const data = await response.json();
            const country = data.results[0]?.components?.country;
            const countryCode = data.results[0]?.components?.country_code;
            const currencyData = countryToCurrency[countryCode.toUpperCase()];
            localStorage.setItem('currency_data', currencyData)
            localStorage.setItem("country", country)
            if (!country) {
                Toast('e', 'Country not found in response');
            } else {
                return;
            }
            return country;
        } catch (error) {
            console.error('Error getting country from coordinates:', error);
            return null;
        }
    }

    useEffect(() => {
        getCurrentLocation()
    }, [])

    return (
        <>
            <div className="extra_margin">
                <div className="d-flex align-items-start justify-content-between">
                    <h1 className="big_font text-white mb-5">{t("common.chooseLang")}</h1>
                    <img src={Assets.logoBall} alt="" width='60px' className="d-md-none d-lg-none d-block animated-image" />
                </div>
                <Select
                    className="common_input"
                    variant="borderless"
                    options={languageList}
                    defaultValue={i18n.language == 'en-GB' || i18n.language == 'en-US' || i18n.language == 'en-IN' ? 'en' : i18n.language}
                    suffixIcon={<Icons.IoIosArrowDown color={`var(--white)`} size={20} />}
                    onChange={handleChangeLanguage}
                />
            </div>
            <div className="btn_border" style={{ position: 'absolute', bottom: '10%', right: '0' }}>
                <CustomButton
                    onClick={() => navigate('/login')}
                    style={{ background: `var(--blue)` }}
                >
                    {t("buttons.next")}
                </CustomButton>
            </div>
        </>
    )
}

export default LanguageSelector;
