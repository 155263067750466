import { useState, useEffect } from "react";

const useWindowHeight = () => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        // Function to update window height on resize
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        // Add event listener on window resize
        window.addEventListener("resize", handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return windowHeight;
};

export default useWindowHeight;
