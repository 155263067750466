import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Assets } from "utils/Assets";
import { handleEnterKeyDown } from "utils/Constant";
import { paymentCardData } from "../redux/features/common/commonSlice";


const PaymentCard = ({ activeCard, setActiveCard, listCard }) => {
    const dispatch = useDispatch();

    const paymentForm = [
        {
            id: 1,
            card_name: 'Credit Card',
            card_number: 'XXXX-XXX-456',
            icon: Assets.visa,
        },
        // {
        //     id: 2,
        //     card_name: 'Apple Pay',
        //     card_number: '',
        //     icon: Assets.applePay,
        // },
        // {
        //     id: 3,
        //     card_name: 'Google Pay',
        //     card_number: '',
        //     icon: Assets.gpay,
        // },
        // {
        //     id: 4,
        //     card_name: 'PayPal',
        //     card_number: '',
        //     icon: Assets.paypal,
        // },
    ];

    useEffect(() => {
        dispatch(paymentCardData())
    }, []);

    return (
        <div className="">
            {paymentForm?.map((item) => (
                <div
                    className="common_card mb-4"
                    key={item.id}
                    role="button"
                    tabIndex={0}
                    onKeyDown={handleEnterKeyDown(item.id, setActiveCard)}
                    onClick={() => { setActiveCard(item.id); dispatch(paymentCardData(item)) }}
                    style={{ height: '60px' }}
                >
                    <div className="d-flex align-items-center gap-2">
                        <div className="radio">
                            <div className={activeCard === item.id ? `mini_radio` : 'mini_radio bg-white'} />
                        </div>
                        <div>
                            <dt className="fw-bold fs-6">
                                {item.id === 1
                                    ? listCard?.brand
                                        ? `${listCard.brand} ${listCard.funding ? `(${listCard.funding})` : ''}`
                                        : "Credit Card"
                                    : item.card_name}
                            </dt>
                            {item.id === 1 && listCard?.last4 &&
                                <dt className="so_small_font text-secondary">XXXX-XXXX-{listCard.last4}</dt>}
                        </div>
                    </div>
                    <img src={item.icon} alt="" />
                </div>
            ))}
        </div>
    )
}

export default PaymentCard;
