import { Input } from "antd";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const PasswordInput = ({ required = true, value, onChange, variant, className, iconColor }) => {
    return (
        <Input.Password
            required={required}
            className={`common_input ${className}`}
            value={value}
            onChange={onChange}
            variant={variant ? variant : "borderless"}
            iconRender={(visible) => visible ? <FaEye color={iconColor} size={15} style={{ cursor: 'pointer' }} /> : <FaEyeSlash color={iconColor} size={15} style={{ cursor: 'pointer' }} />}
        />
    )
}

export default PasswordInput;
