import { Avatar, Modal } from "antd";
import { Assets } from "utils/Assets";
import { useTranslation } from "react-i18next";
import { file_url } from "constant/url";
import { useUpdateTeamMutation } from "../redux/features/myplan/planApi";
import CustomButton from "./CustomButton";
import Toast from "utils/Toast";

const DeleteModal = ({ deleteModal, setDeleteModal, teamDetail }) => {
    const [updateTeam, { isLoading }] = useUpdateTeamMutation();
    const { t } = useTranslation();

    const handleUpdateTeam = async () => {
        try {
            const payload = {
                team_id: teamDetail?._id,
                status: "2"
            };
            const res = await updateTeam(payload).unwrap();
            if (res) {
                setDeleteModal(false);
                Toast("s", res?.message);
            }
        } catch (error) {
            Toast("e", error?.data?.message ?? error?.status);
        }
    };
    return (
        <>
            <Modal open={deleteModal} onCancel={() => setDeleteModal(false)} footer='' closeIcon={<img src={Assets.closeIcon} alt="" />}>
                <h1 className="medium_font fw-bold mt-2 mb-md-4 mb-lg-4">Delete Team</h1>
                <div className="text-center">
                    <Avatar src={teamDetail?.team_image ? `${file_url}${teamDetail?.team_image}` : Assets.darkBallLogo} size={150} />
                    <h6 style={{ color: `var(--text-gray)` }} className="small_font my-4">Are you sure you want to delete this <br /> {teamDetail?.team_name} ?</h6>
                </div>
                <div className="d-flex gap-3 mt-2">
                    <div className="btn_border border-black w-50">
                        <CustomButton
                            style={{ background: `var(--green)`, color: `var(--black)`, width: '100%' }}
                            htmlType='submit'
                            loading={isLoading}
                            onClick={handleUpdateTeam}
                        >
                            {t("buttons.yes")}
                        </CustomButton>
                    </div>
                    <div className="btn_border border-black w-50">
                        <CustomButton
                            style={{ background: `var(--btn-gray)`, color: `var(--black)`, width: '100%' }}
                            onClick={() => setDeleteModal(false)}
                        >
                            {t("buttons.cancel")}
                        </CustomButton>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default DeleteModal;
