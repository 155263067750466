import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Assets } from "utils/Assets";
import { Icons } from "utils/Icons";
import { useTranslation } from "react-i18next";
import { useListTeamQuery } from "../../redux/features/myplan/planApi";
import { file_url } from "constant/url";
import { useDispatch } from "react-redux";
import { teamData } from "../../redux/features/myplan/planSlice";
import { Helmet } from "react-helmet";
import CustomButton from "components/CustomButton";
import CreateTeamModal from "components/CreateTeamModal";
import DeleteModal from "components/DeleteModal";


const MyPlan = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [teamModal, setTeamModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [teamDetail, setTeamDetail] = useState(null);
    const { data: teamList } = useListTeamQuery();

    const handleEditTeam = (item, type) => {
        setTeamDetail(item)
        if (type == 'edit') {
            setTeamModal(true)
        } else {
            setDeleteModal(true)
        }
    }

    return (
        <>
            <Helmet>
                <title>My Plan - Premier Futbol Institute</title>
                <meta name="description" content="Manage your football teams and training plans efficiently." />
                <meta property="og:title" content="My Plan - Premier Futbol Institute" />
                <meta property="og:description" content="Manage your football teams and training plans efficiently." />
                <meta property="og:image" content="https://d3rzktdbhpm4zh.cloudfront.net/web_static_media/drill.png" />
                <meta property="og:url" content="https://dev.d2amudylbskc3c.amplifyapp.com/my-plan" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className="plan_content_height">
                <div className="row g-3 g-md-4 g-lg-4">
                    {teamList?.data?.length > 0 ? teamList?.data?.map((item) => {
                        return (
                            <div className="col-12 col-lg-6" key={item?._id}>
                                <div className="common_card">
                                    <div className="d-flex align-items-center gap-3 w-100" role="button" tabIndex={0} onKeyDown={() => '_'} onClick={() => { navigate(`/session/${item?._id}`); dispatch(teamData(item)) }}>
                                        <div
                                            className="team_image"
                                            style={{
                                                background: item?.team_image ? `url(${file_url}${item?.team_image}) center no-repeat` : `url(${Assets.darkBallLogo}) center no-repeat`,
                                                backgroundSize: 'cover',
                                            }}
                                        />
                                        <div className="text_content">
                                            <h1 className="medium_font">{item?.team_name}</h1>
                                            <h2 className="small_font text-black">{t("header.plan")}: {item?.plan_id?.plan_name}</h2>
                                            <h6 className="small_font" style={{ color: 'var(--text-gray)' }}>{item?.plan_id?.plan_description}</h6>
                                        </div>
                                    </div>
                                    <div className="action_div">
                                        <Icons.IoIosArrowForward size={25} onClick={() => { navigate(`/session/${item?._id}`); dispatch(teamData(item)) }} />
                                        <div className="d-flex gap-3">
                                            <span role="button" tabIndex={0} onKeyDown={() => ''} onClick={() => handleEditTeam(item, 'edit')} className="route_icon" style={{ background: `var(--yellow-normal)` }}>
                                                <img src={Assets.editIcon} alt="" width='20' />
                                            </span>
                                            <span role="button" tabIndex={0} onKeyDown={() => ''} onClick={() => handleEditTeam(item, 'del')} className="route_icon" style={{ background: `var(--pink-bad)` }}>
                                                <img src={Assets.deleteIcon} alt="" width='20' />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }) :
                        <div className="no_data_div">
                            <img alt="" src={Assets.noTeamImg} width='100px' />
                        </div>
                    }
                </div>
            </div>
            <div className="d-block d-sm-none d-md-none d-lg-none mt-4 fixed_button">
                <div className="btn_border border-black">
                    <CustomButton
                        onClick={() => setTeamModal(true)}
                        style={{ background: `var(--green)`, color: `var(--black)`, width: '100%' }}
                    >
                        {t("buttons.create_new_team")}
                    </CustomButton>
                </div>
            </div>

            {/* Team modal */}
            <CreateTeamModal teamModal={teamModal} setTeamModal={setTeamModal} teamDetail={teamDetail} setTeamDetail={setTeamDetail} />
            {/* Delete modal */}
            <DeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} teamDetail={teamDetail} />
        </>
    )
}

export default MyPlan;
