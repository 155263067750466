import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { baseApi } from './baseApi';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import commonReducer from '../features/common/commonSlice';
import planReducer from '../features/myplan/planSlice';
import universalReducer from '../features/universal/universalSlice';


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['common', 'plan', 'universal'],
};

const rootReducer = combineReducers({
    common: commonReducer,
    plan: planReducer,
    universal: universalReducer,
    [baseApi.reducerPath]: baseApi.reducer,
});


const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    [baseApi.reducerPath]: baseApi.reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, }).concat(baseApi.middleware)
});

const persistor = persistStore(store);
export { store, persistor };

