import { useState } from "react";
import { Assets } from "utils/Assets";
import { useSelector } from "react-redux";
import { file_url } from "constant/url";
import { useTranslation } from "react-i18next";
import { Divider } from "antd";
import VideoModal from "components/VideoModal";

const DrillsDetail = () => {
    const { t } = useTranslation();
    const [videoModal, setVideoModal] = useState(false);
    const drillData = useSelector(state => state?.plan?.drill_detail);

    return (
        <>
            <div>
                <div className="row g-3 g-md-4 g-lg-4">
                    <div className="col-12 col-lg-6 ">
                        <div className='d-flex align-items-center gap-3'>
                            {drillData?.media_type === 'video' ?
                                <div style={{ position: 'relative' }} role="button" tabIndex={0} onKeyDown={() => '_'} onClick={() => setVideoModal(true)}>
                                    <video className="video_style" poster={`${file_url}${drillData?.drill_thumbnail}`}>
                                        <track kind="captions" />
                                    </video>
                                    <img src={Assets.playBtn} alt="" width='40px' className="p-0  play_btn_position" />
                                </div>
                                :
                                <img src={`${file_url}${drillData?.drill_media}`} alt="" className="video_style" />
                            }

                            <div className="text_content">
                                <h1 className="medium_font">{t("header.drill")} {drillData?.drill_no}</h1>
                                <h5 className="small_font text-black">{drillData?.drill_title}</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row my-3">
                    <h6 className="small_font text-black">{t("my_plan.drills.exercise_details")}:</h6>
                    <div className="col-12 col-lg-5">
                        <div className="my-3">
                            <h5 className="so_small_font">Objective:</h5>
                            <span className="so_small_font fw-normal">{drillData?.excercise_detail?.objective}</span>
                            <hr />
                        </div>
                        <div className="my-3">
                            <h5 className="so_small_font">Teams:</h5>
                            <h6 className="so_small_font fw-normal">{drillData?.excercise_detail?.teams}</h6>
                            <hr />
                        </div>
                        <div className="my-3">
                            <h5 className="so_small_font">Space:</h5>
                            <h6 className="so_small_font fw-normal">{drillData?.excercise_detail?.space}</h6>
                        </div>
                        <div className="my-3">
                            <h5 className="so_small_font">Coaching Points Based on Universal:</h5>
                            <span className="so_small_font fw-normal">{drillData?.excercise_detail?.objective}</span>
                            <hr />
                        </div>
                    
                    </div>
                    <div className="col-1 text-center">
                        <Divider type="vertical" className="h-100" />
                    </div>
                    <div className="col-12 col-lg-5">
                    <div className="my-3">
                            <h5 className="so_small_font">Questions:</h5>
                            {/* {drillData?.excercise_detail?.questions?.map((item, indx) => (
                                <h6 className="so_small_font fw-normal" key={indx}>{indx + 1} {item}</h6>
                            ))} */}
                            <ul>
                                {drillData?.excercise_detail?.questions?.map((item, indx) => (
                                    <li key={indx} className="so_small_font fw-normal mb-1">{item}</li>
                                ))}
                            </ul>
                            <hr />
                        </div>
                    <div className="my-3">
                            <h5 className="so_small_font">Rules:</h5>
                            {/* {drillData?.excercise_detail?.rules?.map((item, indx) => (
                                <h6 className="so_small_font fw-normal" key={indx}>{item}</h6>
                            ))} */}
                            <ul>
                                {drillData?.excercise_detail?.rules?.map((item, indx) => (
                                    <li key={indx} className="so_small_font fw-normal mb-1">{item}</li>
                                ))}
                            </ul>
                            <hr />
                        </div>
                        <div className="my-3">
                            <h5 className="so_small_font">Levers:</h5>
                            {/* {drillData?.excercise_detail?.levers?.map((item, indx) => (
                                <h6 className="so_small_font fw-normal" key={indx}>{indx + 1} {item}</h6>
                            ))} */}

                            <ul>
                                {drillData?.excercise_detail?.levers?.map((item, indx) => (
                                    <li key={indx} className="so_small_font fw-normal mb-1">{item}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                {/* <footer>
                    <hr />
                    <div className="row mt-4">
                        <div className="col-lg-3 ms-auto">
                            <div className="btn_border border-black">
                                <CustomButton className="text-black w-100" style={{ background: `var(--green)` }}>
                                    Ask Coach
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                </footer> */}
            </div >

            {/* video modal */}
            <VideoModal videoModal={videoModal} setVideoModal={setVideoModal} videosrc={drillData?.drill_media} />
        </>
    )
}

export default DrillsDetail;
