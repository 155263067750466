import { useEffect } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { useContactMutation } from "../../redux/features/settings/settingApi";
import { useSelector } from "react-redux";
import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import TextArea from "antd/es/input/TextArea";
import Toast from "utils/Toast";
import useWindowHeight from "utils/useWindowHeight";

const Support = () => {
    const windowHeight = useWindowHeight();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [sendMessage, { isLoading }] = useContactMutation();

    const userDetail = useSelector(state => state?.common?.user_data);

    const handleSendMessage = async (formVal) => {
        try {
            const payload = {
                name: formVal?.name,
                email: formVal?.email,
                message: formVal?.message,
            }
            const res = await sendMessage(payload).unwrap();
            if (res) {
                Toast("s", res?.message);
                form.setFieldsValue({ "message": "" })
            }
        } catch (error) {
            Toast("e", error?.data?.message ?? error?.status);
        }
    }

    useEffect(() => {
        form.setFieldsValue({
            name: userDetail?.full_name,
            email: userDetail?.email,
        })
    }, []);

    return (
        <>
            <h1 className="medium_font mb-3">Feel free to contact us:</h1>
            <Form form={form} onFinish={handleSendMessage} layout="vertical" className="mt-4">
                <Form.Item name='name' label={<dt className="small_font" style={{ color: 'var(--text-gray)' }}>{t("auth.full_name")}</dt>}>
                    <CustomInput required className='border-black text-dark' placeholder="Name" />
                </Form.Item>

                <Form.Item name='email' label={<dt className="small_font" style={{ color: 'var(--text-gray)' }}>{t("auth.email")}</dt>}>
                    <CustomInput required className='border-black text-dark' placeholder="Email" />
                </Form.Item>

                <Form.Item name='message' label={<dt className="small_font" style={{ color: 'var(--text-gray)' }}>{t("auth.message")}</dt>}>
                    <TextArea required style={{ minHeight: 120 }} variant="borderless" className='common_input border-black text-dark' placeholder="Type your message..." />
                </Form.Item>

                <Form.Item className={windowHeight <= 750 ? 'd-flex justify-content-end extra_margin' : 'auth_btn_position bottom-0'}>
                    <div className="btn_border border-black text-end ">
                        <CustomButton
                            htmlType='submit'
                            className="text-black  w-auto"
                            loading={isLoading}
                            style={{ background: `var(--green)`, color: `var(--black)` }}
                        >
                            {t("buttons.send")}
                        </CustomButton>
                    </div>
                </Form.Item>
            </Form>
        </>
    )
}

export default Support;
