import { Button } from "antd";

const CustomButton = ({
    onClick,
    icon,
    className = '',
    style,
    type = 'ghost', // primary | dashed | link | text | default
    children,
    ghost = true, // true | false
    loading,
    size = "middle", // large | middle | small
    shape = 'default', // default | circle | round
    iconPosition = "start", // start | end
    htmlType,
    block = false, // true | false
    disabled = false, // true | false
}) => (
    <Button
        onClick={onClick}
        className={`common_btn ${className}`}
        style={style}
        type={type}
        icon={icon}
        iconPosition={iconPosition}
        size={size}
        disabled={disabled}
        ghost={ghost}
        block={block}
        htmlType={htmlType}
        shape={shape}
        loading={loading}
    >
        {children}
    </Button>
);

export default CustomButton;

