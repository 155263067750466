import { createSlice } from '@reduxjs/toolkit';

const universalSlice = createSlice({
    name: 'universal',
    initialState: {
        universal_detail: {},
    },
    reducers: {
        universalData: (state, action) => {
            state.universal_detail = action.payload;
        },
    },
});

export const { universalData } = universalSlice.actions;
export default universalSlice.reducer;