import { baseApi } from "../../app/baseApi";
import { endpoints } from "utils/Endpoint";

const commonApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        commonContent: builder.query({
            query: () => ({
                url: endpoints?.common_content,
                method: 'GET',
            }),
        }),
        uploadFile: builder.mutation({
            query: (data) => ({
                url: endpoints?.upload_media,
                method: 'POST',
                body: data
            }),
        }),
        promoCode: builder.mutation({
            query: (data) => ({
                url: endpoints?.promo_code,
                method: 'POST',
                body: data
            }),
        }),
    }),
});

export const { useCommonContentQuery, useUploadFileMutation, usePromoCodeMutation } = commonApi;