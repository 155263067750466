import { baseApi } from "../../app/baseApi";
import { endpoints } from "utils/Endpoint";

const universalApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        universalList: builder.query({
            query: () => ({
                url: endpoints?.universal_list,
                method: 'GET',
            }),
        }),
        universalDetail: builder.query({
            query: (id) => ({
                url: `${endpoints?.universal_detail}${id}`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useUniversalListQuery, useUniversalDetailQuery } = universalApi;