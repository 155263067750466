import { baseApi } from "../../app/baseApi";
import { endpoints } from "utils/Endpoint";

const authApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (data) => ({
                url: endpoints?.login,
                method: 'POST',
                body: data,
            }),
        }),
        register: builder.mutation({
            query: (data) => ({
                url: endpoints?.register,
                method: 'POST',
                body: data,
            }),
        }),
        forgotPassword: builder.mutation({
            query: (data) => ({
                url: endpoints?.forgot_password,
                method: 'POST',
                body: data,
            }),
        }),
        verifyOtp: builder.mutation({
            query: (data) => ({
                url: endpoints?.verify_otp,
                method: 'POST',
                body: data,
            }),
        }),
        resendOtp: builder.mutation({
            query: (data) => ({
                url: endpoints?.resend_otp,
                method: 'POST',
                body: data,
            }),
        }),
        resetPassword: builder.mutation({
            query: (data) => ({
                url: endpoints?.reset_password,
                method: 'POST',
                body: data,
            }),
        }),
    }),
    overrideExisting: false,
});

export const { useLoginMutation, useRegisterMutation, useForgotPasswordMutation, useVerifyOtpMutation, useResendOtpMutation, useResetPasswordMutation } = authApi;
