import { useState, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Assets } from "utils/Assets";
import { Icons } from "utils/Icons";
import { Trans, useTranslation } from "react-i18next";
import { useCompleteDrillMutation, useCycleStepsQuery, useListDrillsQuery } from "../../redux/features/myplan/planApi";
import { file_url } from "constant/url";
import { useDispatch, useSelector } from "react-redux";
import { cycleStepsData, drillDetails, sessionDetail } from "../../redux/features/myplan/planSlice";
import CustomButton from "components/CustomButton";
import Toast from "utils/Toast";

const Drills = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { id } = useParams();
    const { data } = useListDrillsQuery(id);
    const [completeDrill] = useCompleteDrillMutation();
    const reduxData = useSelector(state => state);
    const sessionData = reduxData?.plan?.session_detail;
    const teamID = reduxData?.plan?.cycle_steps?.team_id;
    const { data: cycleSteps, refetch } = useCycleStepsQuery(teamID);

    const [changeButton, setChangeButton] = useState(false);
    const [colorCode, setColorCode] = useState('');
    // Initialize activeTab from localStorage or fallback to session data
    const [activeTab, setActiveTab] = useState(() => {
        return sessionData?.session_performance || '';
    });

    // Buttons configuration
    const btn = useMemo(() => [
        { id: 1, text: <span>{t("buttons.good")}</span>, status: 'good' },
        { id: 2, text: <span>{t("buttons.average")}</span>, status: 'average' },
        { id: 3, text: <span>{t("buttons.poor")}</span>, status: 'poor' }
    ], [t]);

    const handleDrillDetail = (item) => {
        navigate(`/drill-details/${item?._id}`);
        dispatch(drillDetails(item));
    };

    const completeFeedbackDrill = async (status) => {
        try {
            const payload = { session_id: id }
            if (status === 'complete') {
                payload.session_status = "2"
            } else if (status === 'in-complete') {
                payload.session_status = "0"
            } else {
                payload.session_performance = status
            }
            const res = await completeDrill(payload).unwrap();
            if (res) {
                setActiveTab(status);
                Toast("s", res?.message);
                dispatch(sessionDetail(res?.data));
                if (status === 'complete') {
                    setChangeButton(true)
                    refetch();
                } else if (status === 'in-complete') {
                    setChangeButton(false)
                    refetch();
                }
            }
        } catch (error) {
            Toast("e", error?.data?.message ?? error?.status);
        }
    };

    // const completeFeedbackDrill = async (status) => {
    //     try {
    //         const payload = status === 'complete' ?
    //             {
    //                 session_id: id,
    //                 session_status: "2",
    //             } : {
    //                 session_id: id,
    //                 session_performance: status,
    //             };
    //         const res = await completeDrill(payload).unwrap();
    //         if (res) {
    //             Toast("s", res?.message);
    //             if (status === 'complete') {
    //                 setChangeButton(true)
    //             }
    //         }
    //     } catch (error) {
    //         Toast("e", error?.data?.message ?? error?.status);
    //     }
    // };

    const handleButtonClick = (status) => {
        localStorage.setItem('activeTab', status); // Save to localStorage
        completeFeedbackDrill(status);
    };


    useEffect(() => {
        setColorCode(activeTab === 'good' ? 'var(--blue-good)' :
            activeTab === 'average' ? 'var(--yellow-normal)' :
                activeTab === 'poor' ? 'var(--pink-bad)' : '');
    }, [activeTab]);

    useEffect(() => {
        dispatch(cycleStepsData({ cycle: cycleSteps?.data, team_id: teamID }));
    }, [cycleSteps]);


    return (
        <>
            {data?.data?.length > 0 &&
                <>
                    <h1 className="medium_font mb-4">{t("my_plan.drills.universal_session")}</h1>
                    <div className="row my-4">
                        <div className="col-12 col-lg-6">
                            <div className="common_card">
                                <div>
                                    <dt className="medium_font mb-1">{data?.other?.universal}</dt>
                                    <h6 className="so_small_font" style={{ color: 'var(--text-gray)' }}>{data?.other?.universal}</h6>
                                </div>
                                <Icons.IoIosArrowForward size={25} />
                            </div>
                        </div>
                    </div>
                </>
            }
            <div className="content_height">
                <div className="row g-3 g-md-4 g-lg-4">
                    {data?.data?.length > 0 ? data?.data?.map((item) => (
                        <div className="col-12 col-lg-6" key={item?._id} role="button" tabIndex={0} onClick={() => handleDrillDetail(item)} onKeyDown={() => '_'}>
                            <div className="common_card align-items-center">
                                <div className="d-flex align-items-center gap-3">
                                    {item?.media_type === "video" &&
                                        <video
                                            poster={item?.drill_thumbnail ? `${file_url}${item?.drill_thumbnail}` : Assets.drillImg}
                                            className="drill_image"
                                        >
                                            <track kind="captions" />
                                        </video>
                                    }
                                    {item?.media_type === "image" && <img src={item?.drill_media ? `${file_url}${item?.drill_media}` : Assets.drillImg} alt="" className="drill_image" />}
                                    <div className="text_content">
                                        <h4 className="medium_font mb-1">{t("header.drill")} {item?.drill_no}</h4>
                                        <h5 className="small_font text-black mb-1">{item?.drill_title}</h5>
                                        <h6 className="so_small_font" style={{ color: 'var(--text-gray)' }}>{item?.drill_description}</h6>
                                    </div>
                                </div>
                                <Icons.IoIosArrowForward size={25} />
                            </div>
                        </div>
                    )) :

                        <h3 className="medium_font fw-normal">Drills not available</h3>

                    }
                </div>
            </div>
            {data?.data?.length > 0 &&
                <div className="fixed_footer">
                    <hr />
                    <div className="row g-4">
                        <div className="col-lg-12 col-xl-4">
                            <div className="d-flex align-items-center gap-3 justify-content-between justify-content-xl-start">
                                <span className="small_font text-black">
                                    <Trans i18nKey="my_plan.drills.session_complete" components={[{ 1: <br /> }]} />
                                </span>
                                {!changeButton && sessionData?.session_status !== 2 ?
                                    <div className="btn_border border-black w-30">
                                        <CustomButton onClick={() => completeFeedbackDrill('complete')} className="text-black w-auto" style={{ background: `var(--btn-gray)` }}>
                                            {t("buttons.no")}
                                        </CustomButton>
                                    </div>
                                    :
                                    <div className="btn_border border-black w-30 text-end">
                                        <CustomButton onClick={() => completeFeedbackDrill('in-complete')} className="text-black w-auto" style={{ background: `var(--yellow-normal)` }}>
                                            {t("buttons.yes")}
                                        </CustomButton>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-lg-12 col-xl-8">
                            <div className="d-flex align-items-center justify-content-between justify-content-xl-end flex-wrap flex-lg-nowrap gap-3">
                                <span className="small_font text-black">
                                    <Trans i18nKey="my_plan.drills.execute_session" components={[{ 1: <br /> }]} />
                                </span>
                                <div className="btn_border border-black d-flex gap-2 border_btn_width">
                                    {btn.map((item) => (
                                        <CustomButton
                                            onClick={() => handleButtonClick(item?.status)}
                                            key={item.id}
                                            className="text-black w-100 p-0 px-md-5 px-lg-4"
                                            style={{ background: activeTab === item?.status ? colorCode : `var(--white)` }}
                                        >
                                            {item.text}
                                        </CustomButton>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Drills;

