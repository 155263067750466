import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { Assets } from "utils/Assets";
import { useRef, useState } from "react";
import CustomButton from "components/CustomButton";
import useWindowHeight from "utils/useWindowHeight";

const Welcome = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const windowHeight = useWindowHeight();
  const videoRef = useRef();
  const [isPlaying, setIsPlaying] = useState(true);

  const handlePlayVideo = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  }

  const handleVideoPause = () => {
    setIsPlaying(false);
  };

  return (
    <>
      <div className="d-flex flex-column justify-content-between">
        <p className="mt-3 mt-md-0 mt-lg-0">
          <h1 className="big_font text-white m-0">{t("common.welcome")}</h1>
          <h2 className="medium_font text-white">{t("common.to")} <span style={{ color: 'var(--green)' }}>Premier Futbol Institute</span></h2>
        </p>

        <div className="position-relative">
          <video
            ref={videoRef}
            src={`https://d3rzktdbhpm4zh.cloudfront.net/web_static_media/welcome.mp4`}
            onPause={handleVideoPause}
            className="video_style w-100 h-auto"
            autoPlay={true}
            muted
            loop
          >
            <track kind="captions" />
          </video>
          {!isPlaying && (
            <CustomButton className="p-0 w-auto play_btn_position" onClick={handlePlayVideo}>
              <img src={Assets.playBtn} alt="" width='50px' />
            </CustomButton>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <div className={windowHeight <= 700 ? 'btn_border mt-4' : "btn_border auth_btn_position"}>
          <CustomButton
            onClick={() => navigate('/my-plan')}
            style={{ background: `var(--blue)` }}
            className="w-100"
          >
            {t("buttons.done")}
          </CustomButton>
        </div>
      </div>
    </>
  )
}

export default Welcome;
