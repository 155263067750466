import React from "react";
import { Helmet } from "react-helmet";
import Router from "routes/Router";
import Toast from "utils/Toast";

const App = () => {
  React.useEffect(() => {

    const handleOnline = () => {
      Toast("s", "Network connection restored!", "top-center");
      window.location.reload();
    };

    const handleOffline = () => {
      Toast("e", "Network connection lost!", "top-center");
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Premier Futbol Institute</title>
        <meta property="og:title" content="Premier Futbol Institute" />
        <meta property="og:description" content="Providing top-notch training for aspiring football players of all ages." />
        <meta property="og:image" content="https://d3rzktdbhpm4zh.cloudfront.net/web_static_media/mainlogo.png" />
        <meta property="og:url" content="https://dev.d2amudylbskc3c.amplifyapp.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Router />
    </>
  )
}

export default App;
