import { useEffect, useState } from "react";
import { Assets } from "utils/Assets";
import { handleEnterKeyDown } from "utils/Constant";
import { Icons } from "utils/Icons";
import { useMediaQuery } from "react-responsive";
import { useSearchParams } from "react-router-dom";
import { Divider } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { settingData } from "../../redux/features/common/commonSlice";
import { Helmet } from "react-helmet";
import ManageSubscription from "./ManageSubscription";
import AccounInformation from "./AccounInformation";
import ChangeLanguage from "./ChangeLanguage";
import PaymentMethod from "./PaymentMethod";
import Support from "./Support";


const Setting = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [activeCard, setActiveCard] = useState(0);
  const [searchParam, setSearchParam] = useSearchParams(0);
  const isTablet = useMediaQuery({ query: '(max-width: 992px)' });

  const settingCard = [
    { id: 1, img: Assets.user, title: t("settings.account_information") },
    { id: 2, img: Assets.earth, title: t("settings.change_language") },
    { id: 3, img: Assets.subscriptions, title: t("settings.manage_subscriptions") },
    { id: 4, img: Assets.payment, title: t("settings.payment_method") },
    { id: 5, img: Assets.mailIcon, title: t("settings.support") },
  ];

  const handleActiveCard = (item) => {
    setActiveCard(item?.id);
    setSearchParam({ tab: item?.id });
    dispatch(settingData(item))
  };

  useEffect(() => {
    const tabId = Number(searchParam.get("tab"));
    if (tabId) {
      setActiveCard(tabId);
    } else {
      setActiveCard(0)
    }
  }, [searchParam]);

  const renderActiveComponent = () => {
    switch (activeCard) {
      case 1:
        return <AccounInformation />;
      case 2:
        return <ChangeLanguage />;
      case 3:
        return <ManageSubscription />;
      case 4:
        return <PaymentMethod />;
      case 5:
        return <Support />;
      default:
        return null;
    }
  };

  return (
    <>
      <Helmet>
        <title>Settings - Premier Futbol Institute</title>
        <meta name="description" content="Manage your account settings, subscriptions, and more at Premier Futbol Institute." />
        <meta property="og:title" content="Settings - Premier Futbol Institute" />
        <meta property="og:description" content="Manage your account settings, subscriptions, and more at Premier Futbol Institute." />
        <meta property="og:url" content="https://dev.d2amudylbskc3c.amplifyapp.com/settings" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="row g-0 h-100">
        {isTablet && activeCard ? (
          <div className="col-12 col-lg-6 position-relative">
            {renderActiveComponent()}
          </div>
        ) : (
          <>
            <div className="col-lg-5">
              {settingCard.map((item) => (
                <div
                  key={item.id}
                  className="common_card mb-3"
                  style={{ border: activeCard === item.id ? '2px solid #000' : 'none', height: '60px' }}
                  onClick={() => handleActiveCard(item)}
                  role="button"
                  tabIndex={0}
                  onKeyDown={handleEnterKeyDown(item.id, setActiveCard)}
                >
                  <div className="d-flex align-items-center gap-2 gap-lg-3">
                    <img src={item.img} alt="" style={{ width: '25px' }} />
                    <dt className="medium_font">{item.title}</dt>
                  </div>
                  <Icons.IoIosArrowForward size={20} />
                </div>
              ))}
            </div>
            <div className="col-1 text-center">
              <Divider type="vertical" className="h-100" />
            </div>
          </>
        )}
        <div className="col-12 col-lg-6 d-none d-lg-block position-relative">
          {renderActiveComponent()}
        </div>
      </div>
    </>
  );
};

export default Setting;