import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { stripeKey } from "constant/url";
import PaymentForm from "./PaymentForm";

const PaymentModal = ({ setCardModal, cardId }) => {
    const stripePromise = loadStripe(stripeKey);
    return (
        <>
            <Elements stripe={stripePromise}>
                <PaymentForm setCardModal={setCardModal} cardId={cardId} />
            </Elements>
        </>
    )
}

export default PaymentModal;
