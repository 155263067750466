import { Modal } from "antd";
import { Assets } from "utils/Assets";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "./CustomButton";

const PaymentSuccessModal = ({ paymentSuccessModal, setPaymentSuccessModal, planType }) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            <Modal title={<img src={Assets.darkLogo} width={180} alt="" />} open={paymentSuccessModal} footer='' onCancel={() => { location.pathname === '/settings' ? navigate('/my-plan') : navigate('/welcome'); setPaymentSuccessModal(false) }} closeIcon={<img src={Assets.closeIcon} alt="" />}>
                <div className="text-center">
                    <img src={Assets.successGif} alt="" width={200} />
                    {planType?.type === "updated" ?
                        <>
                            <h1 className="big_font fw-bold">Subscription Changed Successfully</h1>
                            <h1 className="medium_font">Next billing mount to be deducted is -- ${planType?.amount}</h1>
                        </>
                        :
                        <h1 className="big_font">Subscription Purchased Successfully</h1>}
                    <div className="btn_border border-black w-50 mx-auto mt-4">
                        <CustomButton onClick={() => { location.pathname === '/settings' ? navigate('/my-plan') : navigate('/welcome'); setPaymentSuccessModal(false) }} className="text-black" style={{ background: `var(--green)`, width: '100%' }}>Next</CustomButton>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default PaymentSuccessModal;
