import lightBall from 'assets/lightball.png';
import logoBall from 'assets/logo.png';
import darkLogo from 'assets/darklogo.png';
import union from 'assets/union.png';
import unionOne from 'assets/union1.png';
import closeIcon from 'assets/close.png';
import visa from 'assets/visa.png';
import applePay from 'assets/applepay.png';
import gpay from 'assets/gpay.png';
import paypal from 'assets/paypal.png';
import upload from 'assets/upload.png';
import footballTeam from 'assets/football-team.jpg';
import editIcon from 'assets/edit.png';
import deleteIcon from 'assets/delete.png';
import darkLightBall from 'assets/darklightball.png';
import whiteLogo from 'assets/whitelogo.png';
import drillImg from 'assets/drill.png';
import trophyImg from 'assets/trophy.png';
import oriented from 'assets/oriented.png';
import dribbling from 'assets/dribbling.png';
import control from 'assets/control.png';
import defend from 'assets/defending.png';
import subscriptions from 'assets/subscriptions.png';
import user from 'assets/user.png';
import earth from 'assets/earth.png';
import payment from 'assets/payment.png';
import playBtn from 'assets/play.png';
import stepsImg from 'assets/step.png';
import mobileStepImg from 'assets/mobilestep.png';
import noTeamImg from 'assets/noteam.png';
import successGif from 'assets/success.gif';
import darkBallLogo from 'assets/darkball.png';
import mailIcon from 'assets/mail.png';

export const Assets = {
    lightBall,
    logoBall,
    union,
    unionOne,
    closeIcon,
    visa,
    applePay,
    gpay,
    paypal,
    darkLogo,
    upload,
    footballTeam,
    editIcon,
    deleteIcon,
    darkLightBall,
    whiteLogo,
    drillImg,
    trophyImg,
    oriented,
    dribbling,
    control,
    defend,
    subscriptions,
    user,
    earth,
    payment,
    playBtn,
    stepsImg,
    mobileStepImg,
    noTeamImg,
    successGif,
    darkBallLogo,
    mailIcon,
}