import { createSlice } from '@reduxjs/toolkit';

const commonSlice = createSlice({
    name: 'common',
    initialState: {
        lang_code: null,
        user_token: {},
        user_data: {},
        common_data: {},
        payment_card: {},
        promocode_data: {},
        setting_data: {},
    },
    reducers: {
        languageCode: (state, action) => {
            state.lang_code = action.payload;
        },
        userToken: (state, action) => {
            state.user_token = action.payload;
        },
        userData: (state, action) => {
            state.user_data = action.payload;
        },
        commonData: (state, action) => {
            state.common_data = action.payload;
        },
        paymentCardData: (state, action) => {
            state.payment_card = action.payload;
        },
        promoCodeData: (state, action) => {
            state.promocode_data = action.payload;
        },
        settingData: (state, action) => {
            state.setting_data = action.payload;
        },
    },
});

export const { languageCode, userToken, userData, commonData, paymentCardData, promoCodeData, settingData } = commonSlice.actions;
export default commonSlice.reducer;