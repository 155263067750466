import { baseApi } from "../../app/baseApi";
import { endpoints } from "utils/Endpoint";

const paymentApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        listSubscription: builder.query({
            query: () => ({
                url: endpoints?.list_subscription,
                method: 'GET',
            }),
        }),
        lisCard: builder.query({
            providesTags: ['listCard'],
            query: () => ({
                url: endpoints?.list_card,
                method: 'GET',
            }),
        }),
        addCard: builder.mutation({
            query: (data) => ({
                url: endpoints?.add_card,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['listCard']
        }),
        deleteCard: builder.mutation({
            query: (data) => ({
                url: endpoints?.delete_card,
                method: 'DELETE',
                body: data
            }),
            invalidatesTags: ['listCard']
        }),
        chargePayment: builder.mutation({
            query: (data) => ({
                url: endpoints?.charge_payment,
                method: 'POST',
                body: data
            }),
        }),
        cancelSubscription: builder.mutation({
            query: (data) => ({
                url: endpoints?.cancel_subscription,
                method: 'POST',
                body: data
            }),
        }),
    }),
});

export const { useListSubscriptionQuery, useAddCardMutation, useLisCardQuery, useDeleteCardMutation, useChargePaymentMutation, useCancelSubscriptionMutation } = paymentApi;