
import { Form } from "antd";
import { useNavigate } from "react-router-dom";
import { Assets } from "utils/Assets";
import { useTranslation } from "react-i18next";
import { useRegisterMutation } from "../../redux/features/auth/authApi";
import { userData } from "../../redux/features/common/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { deviceType } from "utils/Constant";
import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import Toast from "utils/Toast";
import PasswordInput from "components/PasswordInput";

const Register = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [register, { isLoading }] = useRegisterMutation();
    const langCode = useSelector(state => state?.common?.lang_code);

    const handleRegister = async (formVal) => {
        try {
            let payload = {
                full_name: formVal?.full_name?.trim(),
                email: formVal?.email?.toLowerCase(),
                password: formVal?.password?.trim(),
                language: langCode ? langCode : 'en',
                device: deviceType
            };
            const res = await register(payload).unwrap();
            if (res) {
                dispatch(userData(res?.data));
                localStorage.setItem('token', JSON.stringify(res?.data));
                navigate('/verify');
            }

        } catch (error) {
            Toast("e", error?.data?.message ?? error?.status)
        }
    }

    return (
        <>
            <div className="extra_margin mt-md-0 mt-lg-0">
                <div className="d-flex align-items-start justify-content-between">
                    <h1 className="big_font text-white mb-3 mb-md-4 mb-lg-4">
                        {t("auth.register")}
                    </h1>
                    <img src={Assets.logoBall} alt="" width='60px' className="d-md-none d-lg-none d-block" />
                </div>
                <Form onFinish={handleRegister} layout="vertical">
                    <Form.Item name='full_name' label={<dt className="small_font">{t("auth.full_name")}:</dt>}>
                        <CustomInput required={true} />
                    </Form.Item>
                    <Form.Item name='email' label={<dt className="small_font">{t("auth.email")}:</dt>}>
                        <CustomInput type="email" required={true} />
                    </Form.Item>
                    <Form.Item name='password' label={<dt className="small_font">{t("auth.password")}:</dt>}>
                        <PasswordInput />
                    </Form.Item>
                    <Form.Item
                        name='c_password'
                        dependencies={['password']}
                        label={<dt className="small_font">{t("auth.confirm_password")}:</dt>}
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Both password does not match!'));
                                },
                            }),
                        ]}
                    >
                        <PasswordInput />
                    </Form.Item>
                    <Form.Item>
                        <div className="btn_border">
                            <CustomButton
                                loading={isLoading}
                                htmlType='submit'
                                className={` w-100`}
                                style={{ background: `var(--green)`, color: `var(--black)` }}
                            >
                                {t("buttons.create_an_account")}
                            </CustomButton>
                        </div>
                    </Form.Item>
                </Form>
                <dt className="text-end text-white mb-4">{t("auth.already_have_account")}</dt>
                <div className="d-flex justify-content-end">
                    <div className="btn_border">
                        <CustomButton
                            onClick={() => navigate('/login')}
                            style={{ background: `var(--blue)` }}
                        >
                            {t("auth.login")}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Register;
