export const endpoints = {
    // auth endpoints
    login: `user/auth/login`,
    register: `user/auth/register`,
    forgot_password: `user/auth/forgot_password`,
    verify_otp: `user/auth/verify_otp`,
    resend_otp: `user/auth/resend_otp`,
    reset_password: `user/auth/reset_password`,

    // payment endpoint
    list_subscription: `user/auth/list_products`,
    add_card: `user/auth/add_card`,
    list_card: `user/auth/list_cards`,
    delete_card: `user/auth/delete_card`,
    charge_payment: `user/auth/charge_card_payment`,
    cancel_subscription: `user/auth/cancel_subscription`,

    // common upload endpoint
    common_content: `common/get_common_content`,
    upload_media: `user/team/upload_file`,
    //common promocode endpoint
    promo_code: `user/auth/validate_promocode`,

    // my plan endpoints
    list_team: `user/team/list_team`,
    plan_list: `user/plan/list_plans`,
    create_team: `user/team/create_team`,
    edit_team: `user/team/edit_team`,
    // my plan session endpoints
    list_session: `user/user_session/list_user_sessions?team_id=`,
    update_session: `user/user_session/update_user_session`,
    reset_session: `user/user_session/clear_all_user_sessions`,
    session_progress: `user/user_session/get_sessions_result`,
    cycle_steps: `user/user_session/cycle_status_data?team_id=`,
    // my plan drills endpoints
    list_drills: `user/user_session/list_session_drills?session_id=`,
    complete_drills: `user/user_session/edit_user_session`,

    // universal endpoints
    universal_list: `user/universal/list_universal`,
    universal_detail: `user/universal/get_universal?universal_id=`,

    // settings endpoints
    get_user_details: `user/auth/get_user_details`,
    update_user_details: `user/auth/update_profile`,
    change_password: `user/auth/change_password`,
    contact_us: `user/auth/contact_us`,
}