import { useState } from "react";
import { useResendOtpMutation, useVerifyOtpMutation } from "../../redux/features/auth/authApi";
import { useTranslation } from "react-i18next";
import { Assets } from "utils/Assets";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import VerificationInput from "react-verification-input";
import CustomButton from "../../components/CustomButton";
import Toast from "utils/Toast";

const VerifyScreen = ({ email, setActiveScreen }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [otpVal, setOtpVal] = useState('');
    const [type, setType] = useState('');
    const [verify, { isLoading }] = useVerifyOtpMutation();
    const [resend, { isLoading: resendLoader }] = useResendOtpMutation();
    const userData = useSelector(state => state?.common?.user_data);
    const langCode = useSelector(state => state?.common?.lang_code);

    const handleVerifyOtp = async () => {
        setType('')
        try {
            const payload = { email: userData?.email ?? email, otp: otpVal, language: langCode ? langCode : 'en' };
            const res = await verify(payload).unwrap();
            if (res) {
                Toast("s", res?.message);
                if (location.pathname === '/forgot') {
                    setActiveScreen('reset_password')
                } else {
                    navigate('/teams');
                }
            }
        } catch (error) {
            Toast("e", error?.data?.message ?? error?.status);
        }
    };

    const handleResendOtp = async (type) => {
        setType(type)
        try {
            const payload = { email: userData?.email ?? email, language: langCode ? langCode : 'en' };
            const res = await resend(payload).unwrap();
            if (res) {
                Toast("s", res?.message);
            }
        } catch (error) {
            Toast("e", error?.data?.message ?? error?.status);
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-xl-10 col-xxl-12 mx-auto">
                    <div className="extra_margin">
                        <div className="d-flex align-items-start justify-content-between">
                            <h2 className="big_font text-white mb-4">{t("auth.email_verification")}</h2>
                            <img src={Assets.logoBall} alt="" width='60px' className="d-md-none d-lg-none d-block" />
                        </div>
                        <div className="px-1">
                            <dt className='small_font text-white mb-2'>{t('auth.enter_otp')}:</dt>
                            <VerificationInput
                                placeholder="*"
                                length={4}
                                value={otpVal}
                                onChange={(val) => setOtpVal(val)}
                            />
                            <div className="d-flex align-items-center justify-content-between mt-4 mt-md-5 mt-lg-5">
                                <b className="so_small_font text-white">{t('auth.didnt_receive')}</b>
                                <b role="button" tabIndex={0} onKeyDown={() => '_'} onClick={() => handleResendOtp('resend')} className="so_small_font text-white" style={{ cursor: "pointer" }}>{t("buttons.resend")}</b>
                            </div>
                            <div className="btn_border my-4 my-lg-5">
                                <CustomButton
                                    htmlType='submit'
                                    className={` w-100`}
                                    style={{ background: `var(--green)`, color: `var(--black)` }}
                                    loading={!type ? isLoading : resendLoader}
                                    onClick={handleVerifyOtp}
                                >
                                    {t("buttons.continue")}
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VerifyScreen;
