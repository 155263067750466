/**
 * We can access without jwt token
*/
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Assets } from 'utils/Assets';
import SplashScreen from 'pages/auth/SplashScreen';

const NonAuth = () => {
    // const navigate = useNavigate();
    const [languageScreen, setLanguageScreen] = useState(false);
    // const tokn = localStorage.getItem('token');

    useEffect(() => {
        const timer = setTimeout(() => {
            setLanguageScreen(true);
        }, 1500);

        return () => clearTimeout(timer);
    }, []);



    // useEffect(() => {
    //     if (tokn) {
    //         navigate('/my-plan')
    //     }
    // }, [tokn]);

    return (
        <>
            {!languageScreen ? (
                <SplashScreen />
            ) : (
                <div className="auth_div">
                    <div className='row g-0'>
                        <div className='col-md-6 col-lg-6 d-md-block d-lg-block d-none'>
                            <div className="d-flex align-items-center justify-content-between">
                                <img src={Assets.lightBall} alt='' className="animated-image" width='85%' />
                                <div className="border_" />
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-6'>
                            <div className="row g-0">
                                <div className="col-11 col-md-10 col-lg-8 mx-auto right_div" >
                                    <div className="logo_div my-4 my-xl-5 d-none d-md-block d-lg-block">
                                        <div className="d-flex align-items-center gap-3 text-start">
                                            <img src={Assets.logoBall} alt="" className="animated-image" />
                                            <dt className="medium_font text-white">Premier Futbol <br /> Institue</dt>
                                        </div>
                                    </div>
                                    <Outlet />
                                </div>
                                <img src={Assets.unionOne} alt="" className="d-block d-md-none d-lg-none footer_auth_img" />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default NonAuth
