export const handleEnterKeyDown = (itemId, setActiveCard) => (e) => {
  if (e.key === 'Enter') {
    setActiveCard(itemId);
  }
};

const getDeviceType = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  
  if (userAgent.includes("android")) {
    return "Android";
  } else if (userAgent.includes("iphone") || userAgent.includes("ipad") || userAgent.includes("ipod")) {
    return "iOS";
  } else {
    return "Web";
  }
};

export const deviceType = getDeviceType();

